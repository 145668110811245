import React from 'react'

import { ReviewButton } from './RecommendationCardButtons'
import CheckmarkDone from '../icons/checkmark-done-icon.svg'
import CheckmarkNotDone from '../icons/checkmark-not-done-icon.svg'

import './RecommendationCardBatchRow.scss'

export default function RecommendationCardBatchRow({
  isUpdated,
  text,
  photoDetails,
  previouslyUpdated,
  onClick,
}) {
  const statusIcon = isUpdated ? <CheckmarkDone /> : <CheckmarkNotDone />

  return (
    <div className="RecommendationCardBatchRow">
      <div className="status-icon">{statusIcon}</div>
      <div className="body2 black body-text">{text}</div>
      <div className="review-button">
        <ReviewButton
          onClick={() => onClick(photoDetails, text, previouslyUpdated)}
        />
      </div>
    </div>
  )
}
