import React, { useState, useEffect, ReactNode, useMemo } from 'react'

import clsx from 'clsx'

import InteractiveElement from './InteractiveElement'
import {
  CheckPhotosButton,
  CheckSettingsButton,
  SkipButton,
  UndoButton,
  UpdateForMeButton,
  WarningButton,
  WarningCancelButton,
} from './RecommendationCardButtons'
import RecommendationCardSettingRow from './RecommendationCardSettingRow'
import { RecommendationAutomationDef } from '../automations/interfaces'
import CaretIcon from '../icons/caret-icon.svg'
import ExpandSectionIcon from '../icons/expand-icon-closed.svg'
import CloseSectionIcon from '../icons/expand-icon-open.svg'
import GrayBars from '../icons/gray-bars.svg'
import WarningIcon from '../icons/warning-icon.svg'
import * as state from '../state'
import {
  getTimeSinceTimestampStr,
  isMoreThanOneHourAgo,
} from '../utils/dateUtils'
import { RecommendationState } from '../utils/enums'
import { getPlatformDisplayName } from '../utils/platformMetadataUtils'

import './AutomatedRecommendationCardBody.scss'

export default function AutomatedRecommendationCardBody({
  animateFirstRenderForUpdatedCard,
  automationDef,
  automationErrors,
  canUndo,
  customActionDescription,
  defaultBodyTitle,
  initialState,
  isLanguageSupported,
  isLessRelevant,
  isPhoto,
  lastGatheredTimestamp,
  lastUpdatedTimestamp,
  onClickAction,
  onClickScanSettings,
  onClickSkip,
  onClickUndo,
  onClickUndoSkip,
  platform,
  recommendationKey,
  recommendationState,
  settings,
  settingsSelected,
  userId,
  isAnimatingCardStateTransition,
  locked = false,
  truncate = false,
  showGlow = false,
}: {
  animateFirstRenderForUpdatedCard: boolean
  automationDef: RecommendationAutomationDef
  automationErrors: { [key: string]: any } | null
  canUndo: boolean
  customActionDescription?: string
  defaultBodyTitle: React.ReactNode
  initialState: { [key: string]: any } | null
  isLanguageSupported: boolean
  isLessRelevant: boolean
  isPhoto: boolean
  lastGatheredTimestamp: number | null
  lastUpdatedTimestamp: number | null
  onClickAction: () => void
  onClickScanSettings: () => void
  onClickSkip: () => void
  onClickUndo: () => void
  onClickUndoSkip: () => void
  platform: string
  recommendationKey: string
  recommendationState: RecommendationState
  settings: { key: string; description: ReactNode }[]
  settingsSelected: { [key: string]: boolean }
  userId: any
  isAnimatingCardStateTransition: boolean
  locked: boolean
  truncate: boolean
  showGlow: boolean
}) {
  const [showWarning, setShowWarning] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [showFulfilledSettings, setShowFulfilledSettings] = useState(
    recommendationState === RecommendationState.FULFILLED,
  )

  const [shouldTruncate, setShouldTruncate] = useState(truncate)

  useEffect(() => {
    if (lastGatheredTimestamp && Date.now() - lastGatheredTimestamp < 1000) {
      setReloading(true)
      setTimeout(() => {
        setReloading(false)
      }, 500)
    }
  }, [lastGatheredTimestamp])

  const timestampStr: string | undefined = useMemo(() => {
    if (
      recommendationState === RecommendationState.UPDATED &&
      lastUpdatedTimestamp &&
      lastUpdatedTimestamp > -1
    ) {
      // If this recommendation is in an updated state, the recommendation card
      // footer should show the updated time instead of the gathered time
      return `${getTimeSinceTimestampStr(lastUpdatedTimestamp)} ago`
    } else if (lastGatheredTimestamp && lastGatheredTimestamp > -1) {
      return `${getTimeSinceTimestampStr(lastGatheredTimestamp)} ago`
    }
    return undefined
  }, [lastGatheredTimestamp, lastUpdatedTimestamp, recommendationState])

  const toggleSettingState = (settingKey) => {
    const updatedSettings = {
      ...settingsSelected,
      [settingKey]: !settingsSelected[settingKey],
    }

    state.updateSettingsSelected(
      platform,
      userId,
      recommendationKey,
      updatedSettings,
    )
    return updatedSettings
  }

  const settingsFulfilledList: ReactNode[] = []
  const settingsUnfulfilledList: ReactNode[] = []
  let nSettingsSelected = 0
  let nSettingsSelectedUndoable = 0

  settings.forEach((setting) => {
    const isSettingDisabled = initialState
      ? automationDef.getIsDisabledFn(setting.key)?.(automationErrors)
      : true
    const isSettingDisabledReason = initialState
      ? automationDef.getIsDisabledReasonFn(setting.key)?.(automationErrors)
      : null
    const isSettingFulfilled = initialState
      ? automationDef.getIsFulfilledFn(setting.key)?.(initialState)
      : false
    const cantUndoSetting = initialState
      ? automationDef.getCantUndoFn(setting.key)?.(initialState)
      : false
    const currentValue = initialState
      ? automationDef.getCurrentValueFn(setting.key)?.(initialState)
      : null

    const isSettingSelected =
      isSettingFulfilled || isSettingDisabled
        ? false
        : settingsSelected[setting.key]

    if (!isSettingFulfilled && isSettingSelected) {
      nSettingsSelected += 1
      if (cantUndoSetting) {
        nSettingsSelectedUndoable += 1
      }
    }

    const settingRow = (
      <RecommendationCardSettingRow
        currentValueDescription={currentValue}
        key={setting.key}
        isLessRelevant={isLessRelevant}
        isSettingDisabled={isSettingDisabled}
        isSettingDisabledReason={isSettingDisabledReason}
        isSettingFulfilled={isSettingFulfilled}
        isSettingNotUndoable={cantUndoSetting}
        isSettingSelected={isSettingSelected}
        label={setting.description}
        labelId={`${recommendationKey}-${setting.key}-label`}
        recommendationState={recommendationState}
        onSelectSetting={
          locked
            ? () => {}
            : () => {
                setShouldTruncate(false)
                toggleSettingState(setting.key)
              }
        }
        locked={locked}
      />
    )

    if (isSettingFulfilled) {
      settingsFulfilledList.push(settingRow)
    } else {
      settingsUnfulfilledList.push(settingRow)
    }
  })

  const lockedMessage = isLanguageSupported
    ? 'To use this feature, sign up or upgrade your account'
    : `To use this feature, switch your language settings on
      ${getPlatformDisplayName(platform)} to US English`

  const cardIsNotUndoable =
    !canUndo ||
    (nSettingsSelected > 0 && nSettingsSelected === nSettingsSelectedUndoable)

  const nSettingsUpdated =
    recommendationState === RecommendationState.UPDATED
      ? nSettingsSelected
      : null

  const footerDescriptionClassNames = [
    'footer-description',
    'body3',
    'darkest-gray',
  ]
  let footerDescription
  let footerButtons

  if (!initialState) {
    footerButtons = isPhoto ? (
      <CheckPhotosButton onClick={onClickScanSettings} disabled={locked} />
    ) : (
      <CheckSettingsButton onClick={onClickScanSettings} disabled={locked} />
    )
  } else if (recommendationState === RecommendationState.INCOMPLETE || locked) {
    footerDescription = customActionDescription || (
      <>You can undo this settings update later.</>
    )
    footerButtons = (
      <div className={clsx('footer-buttons', showGlow && 'glow')}>
        <UpdateForMeButton
          onClick={onClickAction}
          disabled={locked || nSettingsSelected === 0}
        />
        <SkipButton disabled={locked} onClick={onClickSkip} />
      </div>
    )

    if (cardIsNotUndoable) {
      if (showWarning) {
        footerDescription = (
          <span>
            <strong>Privacy Party cannot undo this later.</strong> Do you want
            to continue?
          </span>
        )
        footerButtons = (
          <>
            <WarningButton onClick={onClickAction} disabled={locked} />
            <WarningCancelButton
              onClick={() => setShowWarning(false)}
              disabled={locked}
            />
          </>
        )
      } else {
        const warning =
          customActionDescription ||
          "After updating, Privacy Party can't undo this update."
        footerDescription = (
          <>
            <WarningIcon
              className="warning-icon"
              aria-label="Warning: can't undo this update"
            />
            <div>{warning}</div>
          </>
        )

        footerButtons = (
          <div className={clsx('footer-buttons', showGlow && 'glow')}>
            <UpdateForMeButton
              onClick={() => setShowWarning(true)}
              disabled={locked || nSettingsSelected === 0}
            />
            <SkipButton onClick={onClickSkip} disabled={locked} />
          </div>
        )
      }
    } else if (nSettingsSelectedUndoable > 0) {
      footerDescription = (
        <>
          <WarningIcon
            className="warning-icon"
            aria-label="Warning: can't undo some changes"
          />
          After updating, Privacy Party can't undo some changes.
        </>
      )
    }
  } else if (recommendationState === RecommendationState.FULFILLED) {
    footerDescriptionClassNames.push(
      timestampStr ? 'space-between' : '',
      'no-buttons-underneath',
    )
    footerDescription = (
      <>
        No action required! You're already good to go 🎉
        <div className="timestamp">{timestampStr}</div>
      </>
    )
  } else if (
    recommendationState === RecommendationState.UPDATED &&
    nSettingsUpdated
  ) {
    footerDescriptionClassNames.push(
      timestampStr || !cardIsNotUndoable ? 'space-between' : '',
      'no-buttons-underneath',
    )
    if (
      cardIsNotUndoable ||
      (lastUpdatedTimestamp && isMoreThanOneHourAgo(lastUpdatedTimestamp))
    ) {
      footerDescription = (
        <>
          You updated {nSettingsUpdated} setting{nSettingsUpdated > 1 && 's'} 🎉
          <div className="timestamp">{timestampStr}</div>
        </>
      )
    } else {
      footerDescription = (
        <>
          You updated {nSettingsUpdated} setting{nSettingsUpdated > 1 && 's'} 🎉
          <UndoButton
            onClick={onClickUndo}
            text="Undo"
            includeIcon={false}
            disabled={locked}
          />
        </>
      )
    }
  } else if (recommendationState === RecommendationState.SKIPPED) {
    footerDescriptionClassNames.push('space-between', 'no-buttons-underneath')
    footerDescription = (
      <>
        You skipped this recommendation.
        <UndoButton
          onClick={onClickUndoSkip}
          text="Undo"
          includeIcon={false}
          disabled={locked}
        />
      </>
    )
  }

  const toggleShowFulfilled = () => {
    setShowFulfilledSettings((prevValue) => !prevValue)
  }

  const truncateLongCard = shouldTruncate && settingsUnfulfilledList.length > 2

  let content = (
    <>
      <div
        className={clsx(
          'recommendation-body',
          recommendationState !== RecommendationState.INCOMPLETE && 'no-title',
          truncateLongCard && 'truncated',
        )}
      >
        {recommendationState === RecommendationState.INCOMPLETE && (
          <div className="recommendation-body-title">
            <div className="body1 black bold">{defaultBodyTitle}</div>
          </div>
        )}
        {truncateLongCard
          ? settingsUnfulfilledList.slice(0, 2)
          : settingsUnfulfilledList}
        {settingsFulfilledList.length > 0 && (
          <div className="recommendation-body-fulfilled">
            <div className="recommendation-body-fulfilled-toggle body3">
              {`${settingsFulfilledList.length} setting${
                settingsFulfilledList.length > 1 ? 's' : ''
              } already safe`}
              {!showFulfilledSettings ? (
                <InteractiveElement
                  aria-label="Show fulfilled settings"
                  aria-expanded={showFulfilledSettings}
                  onClick={toggleShowFulfilled}
                >
                  <ExpandSectionIcon
                    aria-hidden
                    className="expand-fulfilled-icon"
                  />
                </InteractiveElement>
              ) : (
                <InteractiveElement
                  aria-label="Show fulfilled settings"
                  aria-expanded={showFulfilledSettings}
                  onClick={toggleShowFulfilled}
                >
                  <CloseSectionIcon
                    aria-hidden
                    className="expand-fulfilled-icon"
                  />
                </InteractiveElement>
              )}
            </div>
            {showFulfilledSettings && (
              <div className="recommendation-body-fulfilled-settings">
                {settingsFulfilledList}
              </div>
            )}
          </div>
        )}
      </div>
      {truncateLongCard && (
        <InteractiveElement
          className="untruncate-button body3"
          role="button"
          onClick={() => setShouldTruncate(false)}
        >
          See all {settingsUnfulfilledList.length} settings{' '}
          <CaretIcon className="caret-icon" />
        </InteractiveElement>
      )}
      <div
        className={clsx(
          'recommendation-footer',
          truncateLongCard && 'truncated',
        )}
      >
        {footerDescription && !truncateLongCard && (
          <div className={clsx(footerDescriptionClassNames)}>
            {footerDescription}
          </div>
        )}
        {footerButtons}
        {locked && (
          <span aria-hidden className="tooltip-text body3">
            {lockedMessage}
          </span>
        )}
      </div>
    </>
  )
  if (reloading) {
    content = (
      <div className="recommendation-body">
        <div className="check-settings-illustration">
          <GrayBars aria-hidden className="gray-bars" />
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'AutomatedRecommendationCardBody',
        isAnimatingCardStateTransition ||
          (animateFirstRenderForUpdatedCard && 'fade'),
      )}
    >
      {content}
    </div>
  )
}
