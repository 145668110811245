import React, {
  ChangeEventHandler,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { useQuery } from '@apollo/client'
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'

import Row from './Row'
import { Direction } from '../../../__generated__/graphql'
import { USERS } from '../../../queries/users'

const COLSPAN = 6

const headCells = [
  {
    id: 'id',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'email',
    numeric: false,
    label: 'Email',
  },
  {
    id: 'name',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'registered_on',
    numeric: false,
    label: 'Registered On',
  },
  {
    id: 'plan_expires_on',
    numeric: false,
    label: 'Plan Expires On',
  },
]

export default function Users() {
  const [direction, setDirection] = useState<Direction>(Direction.Desc)
  const [rowsPerPage, setRowsPerPage] = useState<number>(50)
  const [orderBy, setOrderBy] = useState<string>('id')
  const [page, setPage] = useState<number>(0)
  const [search, setSearch] = useState<string>('')

  const { data, error, loading, previousData } = useQuery(USERS, {
    variables: {
      input: {
        direction,
        perPage: rowsPerPage,
        orderBy,
        page,
        search,
      },
    },
  })

  const createSortHandler = useCallback(
    (id: string) => () => {
      const isAsc = orderBy === id && direction === Direction.Asc
      setDirection(isAsc ? Direction.Desc : Direction.Asc)
      setOrderBy(id)
    },
    [direction, orderBy],
  )

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement>, newPage: number) => {
      setPage(newPage)
    },
    [],
  )

  const handleRowsPerPageChange: ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
    }, [])

  const debouncedHandleSearchChange = useDebouncedCallback((value: string) => {
    setSearch(value)
  }, 250)

  const result = useMemo(
    () => (loading ? previousData : data),
    [data, loading, previousData],
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          onChange={(event) => debouncedHandleSearchChange(event.target.value)}
          placeholder="Search for ID, email, or name"
          size="small"
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                {headCells.map((c) => (
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === c.id}
                      direction={orderBy === c.id ? direction : Direction.Asc}
                      onClick={createSortHandler(c.id)}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {c.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && !previousData && (
                <TableRow>
                  <TableCell colSpan={COLSPAN}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              )}
              {result?.users?.items.length === 0 && (
                <TableRow>
                  <TableCell colSpan={COLSPAN}>No users</TableCell>
                </TableRow>
              )}
              {error && (
                <TableRow>
                  <TableCell colSpan={COLSPAN}>Error loading users</TableCell>
                </TableRow>
              )}
              {result?.users?.items.map((user) => (
                <Row key={user.id} user={user} />
              ))}
            </TableBody>
            {result?.users?.items && result.users.items.length > 0 && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={COLSPAN}
                    count={result?.users.count}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[25, 50, 100, 200]}
                    sx={{ '& p': { marginBottom: '0' } }}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
