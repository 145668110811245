import React, { ReactNode } from 'react'

import { PLATFORM } from './enums'
import {
  forceLoginUrlFacebook,
  forceLoginUrlGoogle,
  forceLoginUrlInstagram,
  forceLoginUrlLinkedin,
  forceLoginUrlReddit,
  forceLoginUrlStrava,
  forceLoginUrlTwitter,
  forceLoginUrlVenmo,
  forceLoginUrlYoutube,
  loginUrlFacebook,
  loginUrlGoogle,
  loginUrlInstagram,
  loginUrlLinkedIn,
  loginUrlReddit,
  loginUrlStrava,
  loginUrlTwitter,
  loginUrlVenmo,
  loginUrlYoutube,
  platformUrlFacebook,
  platformUrlGoogle,
  platformUrlInstagram,
  platformUrlLinkedin,
  platformUrlReddit,
  platformUrlStrava,
  platformUrlTwitter,
  platformUrlVenmo,
  platformUrlYoutube,
} from '../consts/urlConsts'
import PlatformLogoFacebook from '../icons/platform-logo-facebook.svg'
import PlatformLogoGoogle from '../icons/platform-logo-google.svg'
import PlatformLogoInstagram from '../icons/platform-logo-instagram.svg'
import PlatformLogoLinkedin from '../icons/platform-logo-linkedin.svg'
import PlatformLogoReddit from '../icons/platform-logo-reddit.svg'
import PlatformLogoStrava from '../icons/platform-logo-strava.svg'
import PlatformLogoTwitter from '../icons/platform-logo-twitter.svg'
import PlatformLogoVenmo from '../icons/platform-logo-venmo.svg'
import PlatformLogoYoutube from '../icons/platform-logo-youtube.svg'

const disabledPlatforms: PLATFORM[] = []

export function getEnabledPlatforms() {
  return Object.keys(PLATFORM).filter(
    (key) => !disabledPlatforms.includes(PLATFORM[key]),
  )
}

export function isValidEnabledPlatform(platform: string): boolean {
  return getEnabledPlatforms().includes(platform)
}

export function getLogoFromPlatform(
  platform: PLATFORM,
  logoClassName = '',
): ReactNode {
  return {
    [PLATFORM.FACEBOOK]: (
      <PlatformLogoFacebook aria-hidden className={logoClassName} />
    ),
    [PLATFORM.GOOGLE]: (
      <PlatformLogoGoogle aria-hidden className={logoClassName} />
    ),
    [PLATFORM.INSTAGRAM]: (
      <PlatformLogoInstagram aria-hidden className={logoClassName} />
    ),
    [PLATFORM.LINKEDIN]: (
      <PlatformLogoLinkedin aria-hidden className={logoClassName} />
    ),
    [PLATFORM.REDDIT]: (
      <PlatformLogoReddit aria-hidden className={logoClassName} />
    ),
    [PLATFORM.TWITTER]: (
      <PlatformLogoTwitter aria-hidden className={logoClassName} />
    ),
    [PLATFORM.STRAVA]: (
      <PlatformLogoStrava aria-hidden className={logoClassName} />
    ),
    [PLATFORM.VENMO]: (
      <PlatformLogoVenmo aria-hidden className={logoClassName} />
    ),
    [PLATFORM.YOUTUBE]: (
      <PlatformLogoYoutube aria-hidden className={logoClassName} />
    ),
  }[platform]
}

export function getPlatformDisplayName(platform: PLATFORM | string): string {
  const nameMap: Record<PLATFORM, string> = {
    FACEBOOK: 'Facebook',
    GOOGLE: 'Google',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    REDDIT: 'Reddit',
    STRAVA: 'Strava',
    TWITTER: 'X (Twitter)',
    VENMO: 'Venmo',
    YOUTUBE: 'YouTube',
  }

  return nameMap[platform]
}

export function getPlatformForceLoginUrl(platform: PLATFORM) {
  return {
    FACEBOOK: forceLoginUrlFacebook,
    GOOGLE: forceLoginUrlGoogle,
    INSTAGRAM: forceLoginUrlInstagram,
    LINKEDIN: forceLoginUrlLinkedin,
    REDDIT: forceLoginUrlReddit,
    STRAVA: forceLoginUrlStrava,
    TWITTER: forceLoginUrlTwitter,
    VENMO: forceLoginUrlVenmo,
    YOUTUBE: forceLoginUrlYoutube,
  }[platform]
}

export function getPlatformLoginUrl(platform: PLATFORM) {
  return {
    [PLATFORM.FACEBOOK]: loginUrlFacebook,
    [PLATFORM.GOOGLE]: loginUrlGoogle,
    [PLATFORM.INSTAGRAM]: loginUrlInstagram,
    [PLATFORM.LINKEDIN]: loginUrlLinkedIn,
    [PLATFORM.REDDIT]: loginUrlReddit,
    [PLATFORM.STRAVA]: loginUrlStrava,
    [PLATFORM.TWITTER]: loginUrlTwitter,
    [PLATFORM.VENMO]: loginUrlVenmo,
    [PLATFORM.YOUTUBE]: loginUrlYoutube,
  }[platform]
}

export function getPlatformUrl(platform: PLATFORM) {
  return {
    [PLATFORM.FACEBOOK]: platformUrlFacebook,
    [PLATFORM.GOOGLE]: platformUrlGoogle,
    [PLATFORM.INSTAGRAM]: platformUrlInstagram,
    [PLATFORM.LINKEDIN]: platformUrlLinkedin,
    [PLATFORM.REDDIT]: platformUrlReddit,
    [PLATFORM.STRAVA]: platformUrlStrava,
    [PLATFORM.TWITTER]: platformUrlTwitter,
    [PLATFORM.VENMO]: platformUrlVenmo,
    [PLATFORM.YOUTUBE]: platformUrlYoutube,
  }[platform]
}

const PLATFORM_DEFAULT_DISPLAY_ORDER: Record<PLATFORM, number> = {
  [PLATFORM.TWITTER]: 1,
  [PLATFORM.LINKEDIN]: 2,
  [PLATFORM.VENMO]: 3,
  [PLATFORM.FACEBOOK]: 4,
  [PLATFORM.INSTAGRAM]: 5,
  [PLATFORM.REDDIT]: 6,
  [PLATFORM.GOOGLE]: 7,
  [PLATFORM.YOUTUBE]: 8,
  [PLATFORM.STRAVA]: 9,
}

export function platformDefaultDisplaySortFn(a: PLATFORM, b: PLATFORM) {
  return PLATFORM_DEFAULT_DISPLAY_ORDER[a] - PLATFORM_DEFAULT_DISPLAY_ORDER[b]
}
