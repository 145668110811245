import React from 'react'

import clsx from 'clsx'

import ActionLogger from './ActionLogger'
import AppleMailIcon from '../icons/apple-mail-icon.svg'
import BackArrowIcon from '../icons/back-arrow-icon.svg'
import CheckPhotosIcon from '../icons/check-photos-icon.svg'
import CheckmarkIcon from '../icons/checkmark-icon.svg'
import GmailIcon from '../icons/gmail-icon.svg'
import PauseIcon from '../icons/pause-icon-no-bg.svg'
import PlayIcon from '../icons/play-icon-no-bg.svg'
import SectionCollapseIcon from '../icons/section-collapse-icon.svg'
import SectionExpandIcon from '../icons/section-expand-icon.svg'
import SettingsCheckIcon from '../icons/settings-check-icon.svg'
import UndoIcon from '../icons/undo-icon.svg'
import XIcon from '../icons/x-icon.svg'

import './ActionButton.scss'

export enum ActionButtonSize {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum ActionButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SECONDARY_ROW = 'SECONDARY_ROW',
  TERTIARY = 'TERTIARY',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
}

export enum ActionButtonIcon {
  APPLE_MAIL = 'apple-mail-icon',
  BACK_ARROW = 'back-arrow-icon',
  CANCEL = 'cancel-icon',
  CHECKMARK = 'checkmark-icon',
  GMAIL = 'gmail-icon',
  PAUSE = 'pause-icon',
  PHOTOS_CHECK = 'photos-check-icon',
  PLAY = 'play-icon',
  SECTION_COLLAPSE = 'section-collapse',
  SECTION_EXPAND = 'section-expand',
  SETTINGS_CHECK = 'settings-check-icon',
  UNDO = 'undo-icon',
}

const iconMap: Record<
  ActionButtonIcon,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [ActionButtonIcon.BACK_ARROW]: BackArrowIcon,
  [ActionButtonIcon.CHECKMARK]: CheckmarkIcon,
  [ActionButtonIcon.UNDO]: UndoIcon,
  [ActionButtonIcon.CANCEL]: XIcon,
  [ActionButtonIcon.SETTINGS_CHECK]: SettingsCheckIcon,
  [ActionButtonIcon.PHOTOS_CHECK]: CheckPhotosIcon,
  [ActionButtonIcon.GMAIL]: GmailIcon,
  [ActionButtonIcon.APPLE_MAIL]: AppleMailIcon,
  [ActionButtonIcon.PAUSE]: PauseIcon,
  [ActionButtonIcon.PLAY]: PlayIcon,
  [ActionButtonIcon.SECTION_EXPAND]: SectionExpandIcon,
  [ActionButtonIcon.SECTION_COLLAPSE]: SectionCollapseIcon,
}

export default function ActionButton({
  ariaLabel,
  children,
  className = '',
  disabled = false,
  iconBorder = false,
  iconName,
  iconOnly = false,
  onClick = () => {},
  role,
  size = ActionButtonSize.SMALL,
  type = ActionButtonType.PRIMARY,
  htmlType = 'button',
}: React.PropsWithChildren<{
  ariaLabel?: string
  className?: string
  disabled?: boolean
  iconBorder?: boolean
  iconName?: ActionButtonIcon
  iconOnly?: boolean
  onClick?: () => void
  role?: string
  size?: ActionButtonSize
  type?: ActionButtonType
  htmlType?: 'button' | 'submit' | 'reset'
}>) {
  // Get the text of the button in a way that appropriately reacts to any changes
  const [buttonTextContent, setButtonTextContent] = React.useState<
    string | null
  >(null)
  const buttonTextGetterRef = React.useCallback((node) => {
    if (node) {
      setButtonTextContent(node.textContent)
    }
  }, [])

  const baseClass = {
    [ActionButtonType.PRIMARY]: 'btn-primary',
    [ActionButtonType.SECONDARY]: 'btn-secondary',
    [ActionButtonType.SECONDARY_ROW]: 'btn-secondary row',
    [ActionButtonType.TERTIARY]: 'btn-tertiary',
    [ActionButtonType.WARNING]: 'btn-warning',
    [ActionButtonType.DANGER]: 'btn-danger',
  }[type]

  const sizeModifierClass = {
    [ActionButtonSize.EXTRA_SMALL]: 'extra-small',
    [ActionButtonSize.SMALL]: 'small',
    [ActionButtonSize.MEDIUM]: 'medium',
    [ActionButtonSize.LARGE]: 'large',
  }[size]

  let icon
  if (iconName) {
    const iconClass = clsx(iconName, 'btn-icon', iconBorder && 'bordered')

    const IconComponent = iconMap[iconName]

    icon = <IconComponent aria-hidden className={iconClass} />
  }

  const allClassNames = clsx(
    'ActionButton',
    className,
    'btn',
    'override',
    baseClass,
    sizeModifierClass,
    iconName && !iconOnly ? 'has-icon' : '',
    iconOnly ? 'icon-only' : '',
  )

  return (
    <ActionLogger
      eventName="button:click"
      buttonTextContent={buttonTextContent?.toString().toLowerCase()}
      getDerivedProps={() => {}}
      actionProps={
        buttonTextContent ? ['onClick', buttonTextContent] : ['onClick']
      }
      label={ariaLabel}
    >
      <button
        aria-label={ariaLabel}
        className={allClassNames}
        onClick={onClick}
        disabled={disabled}
        ref={buttonTextGetterRef}
        role={role}
        type={htmlType}
      >
        {icon}
        {iconOnly ? null : children}
      </button>
    </ActionLogger>
  )
}
