import React, { useContext, useMemo } from 'react'

import LogContextProvider, { LogPropsContext } from './LogContextProvider'
import { track } from '../metrics/track'
import { Interaction } from '../metrics/types'

function getModifiedActionHandlers(
  element: React.ReactElement,
  actionProps: string[],
  loggingFn: Function,
): Record<string, Function> {
  const modifiedHandlers = {}

  actionProps.forEach((actionProp) => {
    if (element.props?.[actionProp]) {
      const originalHandler = element.props[actionProp]

      modifiedHandlers[actionProp] = (...callbackArgs) => {
        try {
          loggingFn(...callbackArgs)
        } catch {
          // do nothing
        }
        originalHandler(...callbackArgs)
      }
    }
  })

  return modifiedHandlers
}

function ActionLogger({
  actionProps,
  eventName,
  children,
  getDerivedProps,
  ...logEventProps
}: React.PropsWithChildren<{
  actionProps: string[]
  eventName: 'button:click' | 'element:click' | 'slider:click' | 'toggle:click'
  getDerivedProps: (event: any) => { [propName: string]: any } | void
  [logEventProp: string]: any
}>) {
  const consumedProps = useContext(LogPropsContext)

  const combinedProps = useMemo(
    () => ({
      ...consumedProps,
      ...logEventProps,
    }),
    [consumedProps, logEventProps],
  )

  const loggingFn = (event) => {
    let derivedProps
    try {
      derivedProps = getDerivedProps(event)
    } catch {
      // do nothing
    }

    const properties = {
      ...combinedProps,
      ...(derivedProps || {}),
    }

    const interaction: Interaction = {
      name: eventName,
      properties,
    }

    return track(interaction)
  }

  const modifiedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }

    return React.cloneElement(
      child,
      getModifiedActionHandlers(child, actionProps, loggingFn),
    )
  })

  return (
    <LogContextProvider {...logEventProps}>
      {modifiedChildren}
    </LogContextProvider>
  )
}

export default ActionLogger
