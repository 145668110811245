import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import clsx from 'clsx'

import {
  TaskButton,
  WarningButton,
  WarningCancelButton,
} from './RecommendationCardButtons'
import TaskContentSelectDeleteTimeframe from './TaskContentSelectDeleteTimeframe'
import TaskContentSelectXPost from './TaskContentSelectTwitterPost'
import styles from './TaskRecommendationCardBody.module.scss'
import { getTimeSinceTimestampStr } from '../utils/dateUtils'
import { TwitterRecommendationKey, RecommendationState } from '../utils/enums'

export default function TaskRecommendationCardBody({
  automationErrors,
  confirmTaskButtonText,
  continuingTaskButtonText,
  initialState,
  lastUpdatedTimestamp,
  locked,
  onConfirmClick,
  platformUserId,
  recommendationKey,
  recommendationState,
  settingsSelected,
  statusText,
  taskButtonText,
  taskContext,
  taskTitle,
  taskUpdatedText,
  taskWarningText,
}: {
  automationErrors: any
  confirmTaskButtonText: ReactNode
  continuingTaskButtonText: ReactNode
  initialState: any
  lastUpdatedTimestamp: number | null
  locked: boolean
  onConfirmClick: () => {}
  platformUserId: string
  recommendationKey: string
  recommendationState: RecommendationState
  settingsSelected: any
  statusText: ReactNode
  taskButtonText: ReactNode
  taskContext: string[]
  taskTitle: ReactNode
  taskUpdatedText: ReactNode
  taskWarningText: ReactNode
}) {
  const [taskIsEnabled, setTaskIsEnabled] = useState<boolean>(false)
  const [buttonHelperText, setButtonHelperText] = useState<string | undefined>(
    undefined,
  )

  const [cardFooterText, setCardFooterText] = useState<ReactNode>(() => {
    if (statusText) {
      return statusText
    }

    if (recommendationState === RecommendationState.UPDATED) {
      return taskUpdatedText
    }

    return null
  })

  const updateButtonHelperText = useCallback(
    (text) => setButtonHelperText(text),
    [setButtonHelperText],
  )

  const handleTaskButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      setTaskIsEnabled(true)
    }, [])

  const handleWarningButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      onConfirmClick()
    }, [onConfirmClick])

  const handleWarningCancelButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      setTaskIsEnabled(false)
    }, [])

  const timestampStr: string | undefined = useMemo(() => {
    if (
      recommendationState === RecommendationState.UPDATED &&
      lastUpdatedTimestamp &&
      lastUpdatedTimestamp > -1
    ) {
      return `${getTimeSinceTimestampStr(lastUpdatedTimestamp)} ago`
    }
    return undefined
  }, [lastUpdatedTimestamp, recommendationState])

  const handleUpdateFooterText = useCallback(
    (text) => setCardFooterText(text),
    [],
  )

  let taskButtonDisabled
  switch (recommendationKey) {
    case TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS:
      taskButtonDisabled =
        locked ||
        !initialState?.postInfo ||
        !initialState?.postInfo?.authorUsername
      break
    default:
      taskButtonDisabled = locked
  }

  let taskContent = (
    <>
      <div className={styles['recommendation-body-title']}>
        <div className="body1 black bold">{taskTitle}</div>
      </div>
      <ul className={clsx('body2', 'black')}>
        {taskContext.map((ac) => (
          <li key={ac}>{ac}</li>
        ))}
      </ul>
    </>
  )

  if (recommendationKey === TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS) {
    taskContent = (
      <TaskContentSelectXPost
        defaultTaskTitle={taskTitle}
        hasError={Boolean(automationErrors?.message)}
        locked={locked}
        postAuthorName={initialState?.postInfo?.authorName}
        postAuthorUsername={initialState?.postInfo?.authorUsername}
        postContent={initialState?.postInfo?.content}
        postRepostCount={initialState?.postInfo?.repostCount}
      />
    )
  } else if (
    recommendationKey === TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE
  ) {
    taskContent = (
      <TaskContentSelectDeleteTimeframe
        locked={locked}
        platformUserId={platformUserId}
        updateTaskButtonText={updateButtonHelperText}
        updateFooterText={handleUpdateFooterText}
        initialNumPosts={initialState?.numPosts}
        initialMostRecentPostDate={initialState?.mostRecentPostDate}
        initialCountDeleted={initialState?.count}
        selectedDateOption={settingsSelected?.dateOption}
        selectedMonth={settingsSelected?.sinceDateMonth}
        selectedYear={settingsSelected?.sinceDateYear}
      />
    )
  }

  return (
    <div className={styles.taskRecommendationCardBody}>
      <div className={styles.recommendationBody}>{taskContent}</div>
      <div className={styles.recommendationFooter}>
        {taskIsEnabled && (
          <div
            className={clsx(
              styles['footer-description'],
              'body3',
              'darkest-gray',
            )}
          >
            {taskWarningText ||
              `This can't be undone. Are you sure you want to continue?`}
          </div>
        )}
        {!taskIsEnabled && cardFooterText && (
          <div
            className={clsx(
              styles['footer-description'],
              'body3',
              'darkest-gray',
            )}
          >
            <div>{cardFooterText}</div>
            <div>{timestampStr ? `${timestampStr}` : ''}</div>
          </div>
        )}
        <div className={styles['footer-buttons']}>
          {!taskIsEnabled && (
            <TaskButton
              buttonText={
                recommendationState === RecommendationState.INCOMPLETE
                  ? taskButtonText
                  : continuingTaskButtonText
              }
              buttonHelperText={buttonHelperText}
              disabled={taskButtonDisabled}
              onClick={handleTaskButtonClick}
            />
          )}
          {taskIsEnabled && (
            <>
              <WarningButton
                buttonText={confirmTaskButtonText}
                disabled={locked}
                onClick={handleWarningButtonClick}
              />
              <WarningCancelButton onClick={handleWarningCancelButtonClick} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
