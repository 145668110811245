import isEmpty from 'lodash/isEmpty'

import {
  NotificationStatus,
  NotificationType,
} from './notifications/notificationTypes'
import { StoredRecommendationProgress } from './recommendations/recommendationProgressTypes'
import { getSpecForPlatformRecommendation } from './recommendations/recommendationRegistryApi'
import { RecommendationKeyEnum } from './recommendations/recommendationTypes'
import * as communication from './utils/communication'
import { MACHINE_STORAGE_KEY_SUFFIX, STORAGE_KEY } from './utils/enums'
import * as localStorage from './utils/localStorage'
import { isValidEnabledPlatform } from './utils/platformMetadataUtils'
import { isValidPropertyString } from './utils/stringValidator'

function clearState() {
  return localStorage.remove(Object.keys(STORAGE_KEY))
}

interface AccountDataAttributeUpdates {
  /**
   * Used only for Instagram
   */
  accountType?: string
  accountVisibility?: string

  /**
   * Internal; used server-side to identify completed playbooks
   */
  isCompleted?: boolean

  /**
   * Internal; indicates if this data was deleted; helps with sync'ing
   */
  isDeleted?: boolean

  /**
   * Used only for Facebook
   */
  profileUrl?: string

  /**
   * Used for all platforms; displayed on e.g., Dashboard
   */
  username?: string
}

async function getAccountData(platform, userId) {
  if (platform && userId) {
    const blob = await localStorage.getSingle(STORAGE_KEY.ACCOUNT_DATA)
    return blob?.[platform]?.[userId]
  }
  return {}
}

async function setAccountData(
  platform: string,
  platformUserId: string,
  data: AccountDataAttributeUpdates,
  options: { delete?: boolean } = {},
): Promise<void> {
  // Load and set defaults along path
  if (
    !isValidEnabledPlatform(platform) ||
    !isValidPropertyString(platformUserId)
  ) {
    throw new Error(
      `Invalid platform ${platform} or platform user id ${platformUserId}`,
    )
  }

  const blob = await localStorage.getSingle(STORAGE_KEY.ACCOUNT_DATA)
  const updatedBlob = structuredClone(blob || {})

  if (!(platform in updatedBlob)) {
    updatedBlob[platform] = {}
  }

  if (options.delete) {
    updatedBlob[platform][platformUserId] = {
      isDeleted: true,
    }
  } else {
    updatedBlob[platform][platformUserId] = {
      ...data,
      isDeleted: false,
    }
  }

  // Set a last updated time
  updatedBlob[platform][platformUserId].lastUpdatedTimestamp = Date.now()

  return localStorage.set({ [STORAGE_KEY.ACCOUNT_DATA]: updatedBlob })
}

async function updateAccountData(
  platform: string,
  userId: string,
  data: AccountDataAttributeUpdates,
): Promise<void> {
  const blob = await getAccountData(platform, userId)
  const updatedBlob = { ...(blob || {}), ...(data || {}) }
  return setAccountData(platform, userId, updatedBlob)
}

/**
 * Set default values for the ACCOUNT_DATA object.
 */
export async function initAccountData(
  platform: string,
  userId: string,
): Promise<void> {
  const currentBlob = await getAccountData(platform, userId)

  // TODO: This isn't the best check; better would be to define the
  //       expected attributes and create a validator for that.
  if (isEmpty(currentBlob)) {
    await updateAccountData(platform, userId, {
      isDeleted: false,
      isCompleted: false,
    })
  }
}

export async function setAccountDataIsCompleted(
  platform: string,
  platformUserId: string,
  isCompleted: boolean,
) {
  return updateAccountData(platform, platformUserId, { isCompleted })
}

async function getPlatformUserIdPairs() {
  const accountBlob = await localStorage.getSingle(STORAGE_KEY.ACCOUNT_DATA)
  const pairs: [string, string][] = []
  if (accountBlob) {
    Object.keys(accountBlob).forEach((platform) => {
      Object.keys(accountBlob[platform]).forEach((userId) => {
        pairs.push([platform, userId])
      })
    })
  }
  return pairs
}

async function getReccProgressByPlatformAccount(
  platform: string,
  platformUserId: string,
): Promise<{ [key: string]: any }> {
  if (
    !isValidEnabledPlatform(platform) ||
    !isValidPropertyString(platformUserId)
  ) {
    throw new Error(
      `Invalid platform ${platform} or platform user id ${platformUserId}`,
    )
  }

  const blob = await localStorage.getSingle(STORAGE_KEY.RECC_PROGRESS)
  if (blob && blob[platform] && blob[platform][platformUserId]) {
    return blob[platform][platformUserId]
  }
  return {}
}

async function setReccProgressByPlatformAccount(
  platform: string,
  platformUserId: string,
  data: { [key: string]: StoredRecommendationProgress },
): Promise<void> {
  if (
    !isValidEnabledPlatform(platform) ||
    !isValidPropertyString(platformUserId)
  ) {
    throw new Error(
      `Invalid platform ${platform} or platform user id ${platformUserId}`,
    )
  }

  const blob = await localStorage.getSingle(STORAGE_KEY.RECC_PROGRESS)
  const newBlob = structuredClone(blob || {})

  if (!(platform in newBlob)) {
    newBlob[platform] = {}
  }

  newBlob[platform][platformUserId] = data

  return localStorage.set({ [STORAGE_KEY.RECC_PROGRESS]: newBlob })
}

async function updateMultipleRecommendations(
  platform: string,
  platformUserId: string,
  recommendationKeys: string[],
  newData: { [key: string]: any },
  options: { delete?: boolean } = {},
) {
  const progressBlob = await getReccProgressByPlatformAccount(
    platform,
    platformUserId,
  )

  const updatedProgressBlob = { ...progressBlob }

  for (const recommendationKey of recommendationKeys) {
    if (isValidPropertyString(recommendationKey)) {
      if (options.delete) {
        updatedProgressBlob[recommendationKey] = {
          initialState: null,
          automationErrors: null,
          isSkipped: false,
          isUpdated: false,
          lastGatheredTimestamp: null,
          settingsSelected: {},
          isDeleted: true,
        }
      } else {
        updatedProgressBlob[recommendationKey] = {
          ...(updatedProgressBlob?.[recommendationKey] || {}),
          ...newData,
          isDeleted: false,
        }
      }

      updatedProgressBlob[recommendationKey].lastUpdatedTimestamp = Date.now()
    }
  }

  return setReccProgressByPlatformAccount(
    platform,
    platformUserId,
    updatedProgressBlob,
  )
}

async function updateRecommendation(
  platform: string,
  platformUserId: string,
  recommendationKey: RecommendationKeyEnum,
  newData: { [key: string]: any },
  options: {
    delete?: boolean
    doNotUpdateLastUpdatedTimestamp?: boolean
  } = {},
): Promise<void> {
  if (!isValidPropertyString(recommendationKey)) {
    throw new Error(`Invalid recommendation key ${recommendationKey}`)
  }

  const progressBlob = await getReccProgressByPlatformAccount(
    platform,
    platformUserId,
  )

  const recommendationSpec = getSpecForPlatformRecommendation(
    platform,
    recommendationKey,
  )
  const defaultProgress = {
    initialState: null,
    automationErrors: null,
    isFulfilled: false,
    isSkipped: false,
    isUpdated: false,
    lastGatheredTimestamp: null,
    settingsSelected: recommendationSpec.settings
      ? Object.fromEntries(
          recommendationSpec.settings.map((setting) => [setting.key, true]),
        )
      : null,
  }

  const updatedProgressBlob = { ...progressBlob }

  if (options.delete) {
    updatedProgressBlob[recommendationKey] = {
      initialState: null,
      automationErrors: null,
      isFulfilled: false,
      isSkipped: false,
      isUpdated: false,
      lastGatheredTimestamp: null,
      settingsSelected: {},
      isDeleted: true,
    }
  } else {
    updatedProgressBlob[recommendationKey] = {
      ...defaultProgress,
      ...(updatedProgressBlob?.[recommendationKey] || {}),
      ...newData,
      isDeleted: false,
    }
  }

  if (!options?.doNotUpdateLastUpdatedTimestamp) {
    updatedProgressBlob[recommendationKey].lastUpdatedTimestamp = Date.now()
  }

  return setReccProgressByPlatformAccount(
    platform,
    platformUserId,
    updatedProgressBlob,
  )
}

function updateSettingsSelected(
  platform: string,
  platformUserId: string,
  recommendationKey: string,
  data: { [key: string]: boolean },
): Promise<void> {
  return updateRecommendation(
    platform,
    platformUserId,
    recommendationKey as RecommendationKeyEnum,
    {
      settingsSelected: data,
    },
  )
}

function setRecommendationStatus(
  platform: string,
  platformUserId: string,
  recommendationKey: string,
  changeType: {
    updated?: boolean
    undoUpdated?: boolean
    skipped?: boolean
    undoSkipped?: boolean
  },
): Promise<void> {
  if (Object.values(changeType).filter((x) => x).length !== 1) {
    throw new Error('Exactly one of changeType must be set to true')
  }

  let statusBlob
  if (changeType.updated) {
    statusBlob = { isUpdated: true }
  } else if (changeType.undoUpdated) {
    statusBlob = { isUpdated: false }
  } else if (changeType.skipped) {
    statusBlob = { isSkipped: true }
  } else if (changeType.undoSkipped) {
    statusBlob = { isSkipped: false }
  } else {
    throw new Error('Invalid status')
  }

  return updateRecommendation(
    platform,
    platformUserId,
    recommendationKey as RecommendationKeyEnum,
    statusBlob,
  )
}

function setMultipleRecommendationStatuses(
  platform: string,
  platformUserId: string,
  recommendationKeys: string[],
  changeType: {
    updated?: boolean
    undoUpdated?: boolean
    skipped?: boolean
    undoSkipped?: boolean
  },
): Promise<void> {
  if (Object.values(changeType).filter((x) => x).length !== 1) {
    throw new Error('Exactly one of changeType must be set to true')
  }

  let statusBlob
  if (changeType.updated) {
    statusBlob = { isUpdated: true }
  } else if (changeType.undoUpdated) {
    statusBlob = { isUpdated: false }
  } else if (changeType.skipped) {
    statusBlob = { isSkipped: true }
  } else if (changeType.undoSkipped) {
    statusBlob = { isSkipped: false }
  } else {
    throw new Error('Invalid status')
  }

  return updateMultipleRecommendations(
    platform,
    platformUserId,
    recommendationKeys,
    statusBlob,
  )
}

async function setRecommendationTimestamp(platform, recommendationKey) {
  const userId = await communication.getLoginStatus(platform)

  await updateRecommendation(platform, userId, recommendationKey, {
    lastGatheredTimestamp: Date.now(),
  })
}

async function setSetupProgressCompleted(
  platform: string,
  userId: string,
): Promise<void> {
  if (!isValidEnabledPlatform(platform) || !isValidPropertyString(userId)) {
    throw new Error(
      `Invalid platform ${platform} or platform user id ${userId}`,
    )
  }

  const blob = await localStorage.getSingle(STORAGE_KEY.SETUP_PROGRESS)

  const updatedBlob = structuredClone(blob || {})

  if (!(platform in updatedBlob)) {
    updatedBlob[platform] = {}
  }

  if (!(userId in updatedBlob[platform])) {
    updatedBlob[platform][userId] = {}
  }

  updatedBlob[platform][userId].isCompleted = true
  updatedBlob[platform][userId].lastUpdatedTimestamp = Date.now()

  return localStorage.set({ [STORAGE_KEY.SETUP_PROGRESS]: updatedBlob })
}

function getFirstTimeUserModalDismissed() {
  return localStorage
    .getSingle(STORAGE_KEY.FIRST_TIME_USER_MODAL_DISMISSED)
    .then((dismissed) => dismissed)
}

function setFirstTimeUserModalDismissed(dismissed) {
  localStorage.set({ [STORAGE_KEY.FIRST_TIME_USER_MODAL_DISMISSED]: dismissed })
}

function getDashboardPermissionModalClosed() {
  return localStorage
    .getSingle(STORAGE_KEY.DASHBOARD_PERMISSION_HEADER_CLOSED)
    .then((closed) => closed)
}

function setDashboardPermissionModalClosed(closed) {
  return localStorage.set({
    [STORAGE_KEY.DASHBOARD_PERMISSION_HEADER_CLOSED]: closed,
  })
}

function getSurveyAnswered() {
  if (!chrome.storage) {
    return Promise.resolve(true)
  }

  return chrome.storage.local
    .get([STORAGE_KEY.SURVEY_ANSWERED])
    .then((answered) => answered[STORAGE_KEY.USER_WELCOMED])
    .catch(() => false)
}

function setSurveyAnswered(answered) {
  chrome.storage.local.set({
    [STORAGE_KEY.SURVEY_ANSWERED]: answered,
  })
}

function getUserWelcomed() {
  // Our mockChrome Storybook helper now includes chrome.storage, so check to
  // see if it is a mocked version of it or not
  if (!chrome.storage || (chrome.storage as any).isMocked) {
    return Promise.resolve(true)
  }

  return chrome.storage.local
    .get([STORAGE_KEY.USER_WELCOMED])
    .then((welcomed) => welcomed[STORAGE_KEY.USER_WELCOMED])
    .catch(() => false)
}

function setUserWelcomed(welcomed) {
  chrome.storage.local.set({
    [STORAGE_KEY.USER_WELCOMED]: welcomed,
  })
}

function getDebugData() {
  return localStorage
    .getSingle(STORAGE_KEY.DEBUG_DATA)
    .then((debugData) => debugData)
}

function setDebugData(debugData) {
  localStorage.set({ [STORAGE_KEY.DEBUG_DATA]: debugData })
}

function constructMachineContextKey(machineName) {
  return machineName + MACHINE_STORAGE_KEY_SUFFIX.CONTEXT
}

function setMachineContext(platform, context) {
  const machineContextKey = constructMachineContextKey(platform)

  return localStorage.set({ [machineContextKey]: context })
}

function updateMachineContext(platform, context) {
  const machineContextKey = constructMachineContextKey(platform)

  return localStorage.getSingle(machineContextKey).then((prevContext) =>
    localStorage.set({
      [machineContextKey]: {
        ...(prevContext || {}),
        ...context,
      },
    }),
  )
}

async function initializeNotificationSettings() {
  const blob = Object.fromEntries(
    Object.values(NotificationType).map((key) => [key, true]),
  )
  await localStorage.set({ [STORAGE_KEY.NOTIFICATION_SETTINGS]: blob })
}

async function updateNotificationSetting(notificationType, value) {
  const blob = await localStorage.getSingle(STORAGE_KEY.NOTIFICATION_SETTINGS)
  blob[notificationType] = value
  await localStorage.set({ [STORAGE_KEY.NOTIFICATION_SETTINGS]: blob })
}

async function updateNotificationStatus(notificationId, status) {
  const currentNotifications = await localStorage.getSingle(
    STORAGE_KEY.NOTIFICATION_DATA,
  )

  if (notificationId in currentNotifications) {
    currentNotifications[notificationId].status = status

    await localStorage.set({
      [STORAGE_KEY.NOTIFICATION_DATA]: currentNotifications,
    })
  }
}

async function updateAllNewNotificationStatusAsSeen(notificationData) {
  const currentNotifications: { [id: string]: { status: NotificationStatus } } =
    await localStorage.getSingle(STORAGE_KEY.NOTIFICATION_DATA)

  Object.entries(currentNotifications).forEach(([id, notification]) => {
    if (
      notification.status === NotificationStatus.NEW &&
      id in notificationData
    ) {
      notification.status = NotificationStatus.SEEN // eslint-disable-line no-param-reassign
    }
  })

  return localStorage.set({
    [STORAGE_KEY.NOTIFICATION_DATA]: currentNotifications,
  })
}

async function setLabPartyOpenByPlatform(platform, isOpen) {
  if (!isValidEnabledPlatform(platform)) {
    throw new Error(`Invalid platform ${platform}`)
  }

  const sidebarLabParty = await localStorage.getSingle(
    STORAGE_KEY.SIDEBAR_LAB_PARTY,
  )

  return localStorage.set({
    [STORAGE_KEY.SIDEBAR_LAB_PARTY]: {
      ...(sidebarLabParty || {}),
      [platform]: isOpen,
    },
  })
}

export {
  clearState,
  getAccountData,
  getDashboardPermissionModalClosed,
  getDebugData,
  getFirstTimeUserModalDismissed,
  getPlatformUserIdPairs,
  getReccProgressByPlatformAccount,
  setReccProgressByPlatformAccount,
  getSurveyAnswered,
  getUserWelcomed,
  setDashboardPermissionModalClosed,
  setRecommendationStatus,
  setMultipleRecommendationStatuses,
  updateAccountData,
  setDebugData,
  setFirstTimeUserModalDismissed,
  setLabPartyOpenByPlatform,
  setRecommendationTimestamp,
  setSetupProgressCompleted,
  setSurveyAnswered,
  setUserWelcomed,
  updateSettingsSelected,
  constructMachineContextKey,
  setMachineContext,
  updateMachineContext,
  initializeNotificationSettings,
  updateNotificationSetting,
  updateNotificationStatus,
  updateAllNewNotificationStatusAsSeen,
  updateRecommendation,
}
