import React from 'react'

import { useRequest } from 'ahooks'
import clsx from 'clsx'

import { dayInMs } from '$extensionSrc/utils/dateUtils'

import {
  getDateRange,
  getIsoDateString,
  getPriorSunday,
} from '../utils/dateHelpers'
import { getRequestUrlForKey } from '../utils/metricsApiRequests'

import './HomePageMetrics.scss'

function useMetricsApiDataRequestHook(k, dateKey) {
  return useRequest(async () => {
    const response = await fetch(getRequestUrlForKey(k, dateKey))
    const data = await response.json()
    if (data?.timeseries) {
      return {
        timeseries: Object.fromEntries(
          data.timeseries.map(({ date, value }) => [
            getIsoDateString(new Date(date)),
            value,
          ]),
        ),
        lastUpdatedOn: new Date(data.lastUpdatedOn),
      }
    }
    return {}
  })
}

function aggRecordsMatchingDates(
  records: { [datestring: string]: number },
  dates: Date[],
) {
  return dates
    .map(getIsoDateString)
    .map((dateString) => records[dateString] || 0)
    .reduce((a, b) => a + b, 0)
}

function MetricsBox({
  title,
  thisWeek,
  thisTimeLastWeek,
  lastWeek,
  lastUpdatedOn,
}) {
  const olderThanOneDay =
    new Date().setHours(0, 0, 0, 0) -
      new Date(lastUpdatedOn).setHours(0, 0, 0, 0) >
    dayInMs

  return (
    <div className="MetricsBox">
      <h2>{title}</h2>
      <div className="this-week">
        <span className={clsx(olderThanOneDay && 'red')}>
          <span className="white">{olderThanOneDay && '*'}</span>
          {thisWeek}
          <span>{olderThanOneDay && '*'}</span>
        </span>
      </div>
      <div className="last-week body3">
        <div>This time last week: {thisTimeLastWeek}</div>
        <div>Last week (total): {lastWeek}</div>
        <div>&nbsp;</div>
        <div className={clsx(olderThanOneDay && 'red')}>
          Last updated on: {lastUpdatedOn}
        </div>
        {olderThanOneDay && (
          <div className="red">
            &nbsp;&nbsp;&nbsp;&nbsp;*needs to be updated
          </div>
        )}
      </div>
    </div>
  )
}

export default function HomePageMetrics() {
  const today = new Date()

  const endOfPrevWeek = getPriorSunday(today)
  const startOfPrevWeek = new Date(endOfPrevWeek.getTime() - 6 * dayInMs)
  const startOfCurWeek = new Date(endOfPrevWeek.getTime() + dayInMs)

  const dateKey = {
    startDateAsStr: getIsoDateString(startOfPrevWeek),
    endDateAsStr: getIsoDateString(today),
  }

  const { data: installChromeData, loading: installChromeLoading } =
    useMetricsApiDataRequestHook('installs-chrome', dateKey)
  const { data: installFirefoxData, loading: installFirefoxLoading } =
    useMetricsApiDataRequestHook('installs-firefox', dateKey)
  const { data: installEdgeData, loading: installEdgeLoading } =
    useMetricsApiDataRequestHook('installs-edge', dateKey)
  const { data: nRecommendationsData, loading: nRecommendationsLoading } =
    useMetricsApiDataRequestHook('mixpanel-n-recommendations', dateKey)
  const { data: activeUsers, loading: activeUsersLoading } =
    useMetricsApiDataRequestHook('mixpanel-active-users', dateKey)
  const { data: firstTimeActiveUsers, loading: firstTimeActiveUsersLoading } =
    useMetricsApiDataRequestHook('mixpanel-first-time-active-users', dateKey)

  const {
    timeseries: installChromeTs,
    lastUpdatedOn: installChromeLastUpdatedOn,
  } = installChromeData || {}
  const {
    timeseries: installFirefoxTs,
    lastUpdatedOn: installFirefoxLastUpdatedOn,
  } = installFirefoxData || {}
  const { timeseries: installEdgeTs, lastUpdatedOn: installEdgeLastUpdatedOn } =
    installEdgeData || {}
  const {
    timeseries: nRecommendationsTs,
    lastUpdatedOn: nRecommendationsLastUpdatedOn,
  } = nRecommendationsData || {}
  const { timeseries: activeUserTs, lastUpdatedOn: activeUserLastUpdatedOn } =
    activeUsers || {}
  const {
    timeseries: firstTimeActiveUserTs,
    lastUpdatedOn: firstTimeActiveUserLastUpdatedOn,
  } = firstTimeActiveUsers || {}

  const isLoading =
    installChromeLoading ||
    installFirefoxLoading ||
    installEdgeLoading ||
    nRecommendationsLoading ||
    activeUsersLoading ||
    firstTimeActiveUsersLoading

  // Add some stuff up
  if (
    !isLoading &&
    installChromeTs &&
    installFirefoxTs &&
    installEdgeTs &&
    nRecommendationsTs &&
    activeUserTs &&
    firstTimeActiveUserTs
  ) {
    const lastWeeksDates = getDateRange(startOfPrevWeek, endOfPrevWeek)
    const thisTimeLastWeeksDates = getDateRange(
      startOfPrevWeek,
      new Date(today.getTime() - 7 * dayInMs),
    )
    const thisWeeksDates = getDateRange(startOfCurWeek, today)

    const installsLastWeek =
      aggRecordsMatchingDates(installChromeTs, lastWeeksDates) +
      aggRecordsMatchingDates(installFirefoxTs, lastWeeksDates) +
      aggRecordsMatchingDates(installEdgeTs, lastWeeksDates)

    const installsThisTimeLastWeek =
      aggRecordsMatchingDates(installChromeTs, thisTimeLastWeeksDates) +
      aggRecordsMatchingDates(installFirefoxTs, thisTimeLastWeeksDates) +
      aggRecordsMatchingDates(installEdgeTs, thisTimeLastWeeksDates)

    const installsThisWeek =
      aggRecordsMatchingDates(installChromeTs, thisWeeksDates) +
      aggRecordsMatchingDates(installFirefoxTs, thisWeeksDates) +
      aggRecordsMatchingDates(installEdgeTs, thisWeeksDates)

    const nRecommendationsLastWeek = aggRecordsMatchingDates(
      nRecommendationsTs,
      lastWeeksDates,
    )

    const nRecommendationsThisTimeLastWeek = aggRecordsMatchingDates(
      nRecommendationsTs,
      thisTimeLastWeeksDates,
    )

    const nRecommendationsThisWeek = aggRecordsMatchingDates(
      nRecommendationsTs,
      thisWeeksDates,
    )

    const activeUsersLastWeek = aggRecordsMatchingDates(
      activeUserTs,
      lastWeeksDates,
    )

    const activeUsersThisTimeLastWeek = aggRecordsMatchingDates(
      activeUserTs,
      thisTimeLastWeeksDates,
    )

    const activeUsersThisWeek = aggRecordsMatchingDates(
      activeUserTs,
      thisWeeksDates,
    )

    const firstTimeActiveUsersLastWeek = aggRecordsMatchingDates(
      firstTimeActiveUserTs,
      lastWeeksDates,
    )

    const firstTimeActiveUsersThisTimeLastWeek = aggRecordsMatchingDates(
      firstTimeActiveUserTs,
      thisTimeLastWeeksDates,
    )

    const firstTimeActiveUsersThisWeek = aggRecordsMatchingDates(
      firstTimeActiveUserTs,
      thisWeeksDates,
    )

    const installLastUpdatedOn = new Date(
      Math.max(
        installChromeLastUpdatedOn.getTime(),
        installFirefoxLastUpdatedOn.getTime(),
        installEdgeLastUpdatedOn.getTime(),
      ),
    )

    return (
      <div className="HomePageMetrics">
        <div className="MetricsRow">
          <MetricsBox
            title="Installs"
            thisWeek={installsThisWeek}
            thisTimeLastWeek={installsThisTimeLastWeek}
            lastWeek={installsLastWeek}
            lastUpdatedOn={installLastUpdatedOn.toDateString()}
          />
          <MetricsBox
            title="Recommendations"
            thisWeek={nRecommendationsThisWeek}
            thisTimeLastWeek={nRecommendationsThisTimeLastWeek}
            lastWeek={nRecommendationsLastWeek}
            lastUpdatedOn={nRecommendationsLastUpdatedOn.toDateString()}
          />
        </div>
        <div className="MetricsRow">
          <MetricsBox
            title="Active Users"
            thisWeek={activeUsersThisWeek}
            thisTimeLastWeek={activeUsersThisTimeLastWeek}
            lastWeek={activeUsersLastWeek}
            lastUpdatedOn={activeUserLastUpdatedOn.toDateString()}
          />
          <MetricsBox
            title="First Time Active Users"
            thisWeek={firstTimeActiveUsersThisWeek}
            thisTimeLastWeek={firstTimeActiveUsersThisTimeLastWeek}
            lastWeek={firstTimeActiveUsersLastWeek}
            lastUpdatedOn={firstTimeActiveUserLastUpdatedOn.toDateString()}
          />
        </div>
      </div>
    )
  }
}
