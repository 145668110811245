import { gql } from '../__generated__/gql'

export const USERS = gql(`
  query Users($input: AdminUsersInput!) {
    users(input: $input) {
      count
      items {
        email
        id
        name
        planExpiresOn
        registeredOn
      }
    }
  }
`)
