import { gql } from '../__generated__/gql'

export const USER = gql(`
  query User($input: AdminUserInput!) {
    user(input: $input) {
      anonymousUserIds
      attributions
      email
      hasActivePlan
      id
      initialAttributions
      name
      planExpiresOn
      progress
      registeredOn
      registeredWith
      stripeCustomerId
      twofaMethod
    }
  }
`)
