import React from 'react'

import { ApolloProvider } from '@apollo/client'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material'

import Users from './Users'
import { client } from '../../apollo'

const defaultTheme = createTheme({
  typography: {
    allVariants: {
      color: '#212529',
    },
    fontFamily: 'Red Hat Text, Helvetica, sans-serif',
  },
})

export default function UsersPage({ cspNonce }: { cspNonce: string }) {
  const cache = createCache({
    key: 'emotion',
    nonce: cspNonce,
  })

  return (
    <ApolloProvider client={client}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={defaultTheme}>
          <h1>Users</h1>
          <Users />
        </ThemeProvider>
      </CacheProvider>
    </ApolloProvider>
  )
}
