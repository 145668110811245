/**
 * Utilities for helping our React component trees respond to changes in
 * extension storage.
 */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

enum AreaName {
  LOCAL = 'local',
}

function parseStringToAreaNameEnum(area: string): AreaName {
  switch (area) {
    case 'local':
      return AreaName.LOCAL
    default:
      throw new Error(`Invalid storage area: ${area}`)
  }
}

interface KeysByAreaMap {
  [AreaName.LOCAL]?: string[] | null
}

function getStorageArea(area: AreaName) {
  switch (area) {
    case AreaName.LOCAL:
      return chrome.storage.local
    default:
      throw new Error(`Invalid storage area: ${area}`)
  }
}

/**
 * Provided the keys we want, fetch from storage (using appropriate method)
 * and return in a flat object.
 *
 * Note that objects of the same key name across multiple areas will have their
 * values overwritten by one another.
 *
 * `callbackFn` should be, e.g., React's useState method.
 */
async function initializeData(
  keysByArea: KeysByAreaMap,
  callbackFn: (fn: (data) => void) => void,
): Promise<void> {
  // This { [key: string]: any } type is what we'd replace if we ever add good
  // typing to local storage
  const promises: Promise<{ [key: string]: any }>[] = []

  for (const area of Object.values(AreaName)) {
    if (keysByArea[area] !== undefined) {
      promises.push(getStorageArea(area).get(keysByArea[area]))
    }
  }

  const blobs = await Promise.all(promises)

  callbackFn(() => {
    const flatObject = {}
    for (const blob of blobs) {
      for (const [key, value] of Object.entries(blob)) {
        flatObject[key] = value
      }
    }
    return flatObject
  })
}

/**
 * Listen for changes to the provided keys in the provided areas.
 *
 * `callbackFn` should be, e.g., React's useState method.
 */
function createListener(
  keysByArea: KeysByAreaMap,
  // This { [key: string]: any } type is what we'd replace if we ever add good
  // typing to local storage
  callbackFn: Dispatch<SetStateAction<{ [key: string]: any } | null>>,
): (changes: chrome.storage.StorageChange, area: string) => void {
  return (changes: chrome.storage.StorageChange, areaString: string) => {
    const area = parseStringToAreaNameEnum(areaString)

    if (!changes || !Object.keys(changes).length) {
      return
    }

    let relevantKeysFound
    if (keysByArea[area] === undefined) {
      relevantKeysFound = []
    } else if (keysByArea[area] === null) {
      relevantKeysFound = Object.keys(changes)
    } else {
      relevantKeysFound = keysByArea[area].filter((key) => key in changes)
    }

    if (relevantKeysFound.length === 0) {
      return
    }

    callbackFn((data) => {
      const updatedData = { ...data }

      for (const key of relevantKeysFound) {
        const { oldValue, newValue } = changes[key]

        if (oldValue !== undefined && newValue === undefined) {
          delete updatedData[key]
        } else if (newValue !== undefined) {
          updatedData[key] = newValue
        } else {
          // pass
        }
      }

      return updatedData
    })
  }
}

/**
 * Package data initializer & listener utils into a single hook to streamline
 * initializing & then receiving updates to data from storage.
 */
export function useExtensionData(keysByArea: KeysByAreaMap): {
  [key: string]: any
} {
  // This { [key: string]: any } type is what we'd replace if we ever add good
  // typing to local storage
  const [data, setData] = useState<{ [key: string]: any } | null>(null)
  const [isLoaded, setLoaded] = useState(false)

  // We explicitly provide empty dependency arrays to ensure that these
  // do not consistently re-render (objects are never equal to one another).

  // Note: this means that if the keysByArea object is updated, the hook
  // will not respond to those changes. This is intentional.

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const listener = createListener(keysByArea, setData)
    chrome.storage.onChanged.addListener(listener)

    return () => {
      chrome.storage.onChanged.removeListener(listener)
    }
  }, [])

  useEffect(() => {
    initializeData(keysByArea, setData).then(() => setLoaded(true))
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return { isLoaded, data }
}
