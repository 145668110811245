import React, { MouseEventHandler, useMemo } from 'react'

import clsx from 'clsx'

import FriendSet from './FriendSet'
import styles from './FriendSets.module.scss'
import { State } from '../../../automations/facebook/fbUnfriend'
import InteractiveElement from '../../InteractiveElement'

export default function FriendSets({
  allFriendsAreLoaded,
  onBatchSelected,
  onLoadAllFriendsClick,
  state,
}: {
  allFriendsAreLoaded: boolean
  onBatchSelected: (index: number) => void
  onLoadAllFriendsClick: MouseEventHandler<HTMLButtonElement>
  state: State | null
}) {
  const percentage = useMemo(() => {
    if (!state) {
      return 0
    }
    return Math.round(
      (state.batches.filter((b) => b.reviewed).length / state.batches.length) *
        100,
    )
  }, [state])

  if (!state) {
    return null
  }
  return (
    <div className={styles.friendSets}>
      <div className={clsx(styles.heading, 'body3', 'darker-gray')}>
        <div>Sets of friends (up to 100 each)</div>
        <div>Reviewed — {percentage}%</div>
      </div>
      <div
        className={clsx(
          styles.sets,
          allFriendsAreLoaded && styles.allFriendsAreLoaded,
        )}
      >
        <div
          className={clsx(
            styles.scroller,
            allFriendsAreLoaded && styles.allFriendsAreLoaded,
          )}
        >
          {state.batches.map((b, i) => (
            <FriendSet
              index={i}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onBatchSelected={onBatchSelected}
              reviewed={b.reviewed}
            />
          ))}
        </div>
        {!allFriendsAreLoaded && (
          <div className={styles.pagination}>
            <InteractiveElement
              className={styles.loadAllFriends}
              onClick={onLoadAllFriendsClick}
            >
              <div className={clsx('button2', 'purple')}>Load all friends</div>
              <div className={clsx('body3', 'darker-gray')}>
                {state.remaining.toLocaleString()} remaining
              </div>
            </InteractiveElement>
          </div>
        )}
      </div>
    </div>
  )
}
