/* eslint-disable-file max-len */
import { InstagramRecommendationKey } from '../../utils/enums'
import metadata from '../metadata/instagram.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/instagram.yaml'

const INSTAGRAM_RECOMMENDATION_KEYS = [
  InstagramRecommendationKey.INSTAGRAM_ADVANCED_COMMENT_FILTERING,
  InstagramRecommendationKey.INSTAGRAM_ARCHIVE_POSTS,
  InstagramRecommendationKey.INSTAGRAM_DELETE_YOUR_POSTS,
  InstagramRecommendationKey.INSTAGRAM_EMAIL_NOTIFICATIONS_V2,
  InstagramRecommendationKey.INSTAGRAM_HIDE_COMMENTS,
  InstagramRecommendationKey.INSTAGRAM_HIDE_MESSAGES,
  InstagramRecommendationKey.INSTAGRAM_PRIVATE_ACCOUNT,
  InstagramRecommendationKey.INSTAGRAM_PUSH_NOTIFICATIONS,
  InstagramRecommendationKey.INSTAGRAM_SETUP_2FA,
  InstagramRecommendationKey.INSTAGRAM_STATUS_VISIBILITY,
  InstagramRecommendationKey.INSTAGRAM_STORY_REPLIES,
  InstagramRecommendationKey.INSTAGRAM_TAGGING,
  InstagramRecommendationKey.INSTAGRAM_WHO_CAN_COMMENT,
  InstagramRecommendationKey.INSTAGRAM_UNTAG_PHOTOS,
]

const INSTAGRAM_RECOMMENDATIONS_REGISTRY: Record<
  InstagramRecommendationKey,
  Recommendation
> = {
  [InstagramRecommendationKey.INSTAGRAM_ADVANCED_COMMENT_FILTERING]: {
    ...parseSpec(
      specs[InstagramRecommendationKey.INSTAGRAM_ADVANCED_COMMENT_FILTERING],
    ),
    key: InstagramRecommendationKey.INSTAGRAM_ADVANCED_COMMENT_FILTERING,
  },
  [InstagramRecommendationKey.INSTAGRAM_TAGGING]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_TAGGING]),
    key: InstagramRecommendationKey.INSTAGRAM_TAGGING,
  },
  [InstagramRecommendationKey.INSTAGRAM_DELETE_YOUR_POSTS]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_DELETE_YOUR_POSTS]),
    key: InstagramRecommendationKey.INSTAGRAM_DELETE_YOUR_POSTS,
  },
  [InstagramRecommendationKey.INSTAGRAM_EMAIL_NOTIFICATIONS_V2]: {
    ...parseSpec(
      specs[InstagramRecommendationKey.INSTAGRAM_EMAIL_NOTIFICATIONS_V2],
    ),
    key: InstagramRecommendationKey.INSTAGRAM_EMAIL_NOTIFICATIONS_V2,
  },
  [InstagramRecommendationKey.INSTAGRAM_HIDE_COMMENTS]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_HIDE_COMMENTS]),
    key: InstagramRecommendationKey.INSTAGRAM_HIDE_COMMENTS,
  },
  [InstagramRecommendationKey.INSTAGRAM_HIDE_MESSAGES]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_HIDE_MESSAGES]),
    key: InstagramRecommendationKey.INSTAGRAM_HIDE_MESSAGES,
  },
  [InstagramRecommendationKey.INSTAGRAM_PRIVATE_ACCOUNT]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_PRIVATE_ACCOUNT]),
    key: InstagramRecommendationKey.INSTAGRAM_PRIVATE_ACCOUNT,
  },
  [InstagramRecommendationKey.INSTAGRAM_PUSH_NOTIFICATIONS]: {
    ...parseSpec(
      specs[InstagramRecommendationKey.INSTAGRAM_PUSH_NOTIFICATIONS],
    ),
    key: InstagramRecommendationKey.INSTAGRAM_PUSH_NOTIFICATIONS,
  },
  [InstagramRecommendationKey.INSTAGRAM_SETUP_2FA]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_SETUP_2FA]),
    hasAutomatedGather: true,
    isAutomated: false,
    key: InstagramRecommendationKey.INSTAGRAM_SETUP_2FA,
  },
  [InstagramRecommendationKey.INSTAGRAM_STATUS_VISIBILITY]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_STATUS_VISIBILITY]),
    key: InstagramRecommendationKey.INSTAGRAM_STATUS_VISIBILITY,
  },
  [InstagramRecommendationKey.INSTAGRAM_STORY_REPLIES]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_STORY_REPLIES]),
    key: InstagramRecommendationKey.INSTAGRAM_STORY_REPLIES,
  },
  [InstagramRecommendationKey.INSTAGRAM_WHO_CAN_COMMENT]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_WHO_CAN_COMMENT]),
    key: InstagramRecommendationKey.INSTAGRAM_WHO_CAN_COMMENT,
  },
  [InstagramRecommendationKey.INSTAGRAM_UNTAG_PHOTOS]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_UNTAG_PHOTOS]),
    hasAutomatedGather: false,
    isAutomated: false,
    key: InstagramRecommendationKey.INSTAGRAM_UNTAG_PHOTOS,
  },
  [InstagramRecommendationKey.INSTAGRAM_ARCHIVE_POSTS]: {
    ...parseSpec(specs[InstagramRecommendationKey.INSTAGRAM_ARCHIVE_POSTS]),
    key: InstagramRecommendationKey.INSTAGRAM_ARCHIVE_POSTS,
  },
}

export const instagramRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => INSTAGRAM_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => INSTAGRAM_RECOMMENDATION_KEYS,
  getRecommendationsRegistry: () => INSTAGRAM_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
