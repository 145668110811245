import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useRequest } from 'ahooks'
import clsx from 'clsx'

import { getIsoDateString, getDaysSinceSunday } from '../utils/dateHelpers'
import {
  metricsTableKeys,
  getRequestUrlForKey,
} from '../utils/metricsApiRequests'

import './MetricsKeyFunnelTable.scss'

// Date & data manipulation utilities

export const msInDay = 1000 * 60 * 60 * 24
const msInWeek = msInDay * 7

function getDateBuckets() {
  const today = new Date()
  today.setUTCHours(23, 59, 59, 999)

  const mostRecentSunday = new Date(
    today.getTime() - today.getUTCDay() * msInDay,
  )
  const lastTwelveSundays = Array.from(
    { length: 12 },
    (_, i) => new Date(mostRecentSunday.getTime() - i * msInWeek),
  )

  const dateBuckets = [...lastTwelveSundays, today] // right-side non-inclusive
  dateBuckets.sort((a, b) => a.getTime() - b.getTime())

  return dateBuckets
}

function getDaysInWeekLeadingUpToDate(date: Date | string) {
  const lastDay = new Date(date)
  lastDay.setUTCHours(0, 0, 0, 0)

  let days
  if (lastDay.getUTCDay() === 0) {
    days = [6, 5, 4, 3, 2, 1, 0].map(
      (daysAgo) => new Date(lastDay.getTime() - msInDay * daysAgo),
    )
  } else {
    days = []
    for (let i = lastDay.getUTCDay() - 1; i >= 0; i -= 1) {
      days.push(new Date(lastDay.getTime() - msInDay * i))
    }
  }
  return days
}

function aggDataIntoBuckets(
  data: { date: string; value: number }[],
  dateBuckets: Date[],
): (number | null)[] {
  // Assumes date buckets is ordered
  const relevantDateBuckets = dateBuckets.slice(1, dateBuckets.length)

  const countByBucket = relevantDateBuckets.map(() => 0)

  for (const record of data) {
    const date = new Date(record.date)

    // i.e., find the first bucket that the date is less than
    const index = relevantDateBuckets.findIndex((bucket) => date < bucket)
    if (index !== -1) {
      countByBucket[index] += record.value
    }
  }

  return countByBucket
}

export function getIsDataIncomplete(
  currentSeriesKey: string,
  lastUpdatedOn: Record<string, string | null>,
  currentWeek: Date,
  isSpacer: boolean,
): boolean {
  // If a summation cell (e.g. "Total installs") has constituent cells (e.g.
  // Chrome or Firefox or Edge) that have incomplete data, the summation cell
  // should also be marked as incomplete
  const summationKeyToConstituentKeys: Record<string, string[]> = {
    'total-visits': [
      'ga-blockpartyapp',
      'ga-webstore',
      'ga-blockpartyapp-social',
    ],
    'total-installs': ['installs-chrome', 'installs-edge', 'installs-firefox'],
  }
  const constituentKeys = summationKeyToConstituentKeys[currentSeriesKey]
  if (constituentKeys?.length > 0) {
    for (const key of constituentKeys) {
      const ldo = lastUpdatedOn[key]
      if (ldo && currentWeek > new Date(ldo) && !isSpacer) {
        return true
      }
    }
    return false
  }

  // Normal cell handling
  const ldo = lastUpdatedOn[currentSeriesKey]
  return (
    ldo !== undefined &&
    ldo !== null &&
    currentWeek > new Date(ldo) &&
    !isSpacer
  )
}

function getFormattedHeaderForKey(requestKey: string) {
  switch (requestKey) {
    case 'ga-blockpartyapp':
      return (
        <span>
          &nbsp;&nbsp;Blockpartyapp.com&nbsp;
          <a
            href="https://mixpanel.com/s/12jgKu"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'ga-blockpartyapp-social':
      return (
        <span>
          &nbsp;&nbsp;Blockpartyapp.com (social)&nbsp;
          <a
            href="https://mixpanel.com/s/2C9QFX"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'ga-webstore':
      return (
        <span>
          &nbsp;&nbsp;Chrome Webstore&nbsp;
          <a
            href="https://lookerstudio.google.com/s/kflVR71dV_E"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'total-installs':
      return (
        <span>
          Installs&nbsp;
          <a
            href="https://mixpanel.com/s/2lgkqm"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'installs-chrome':
      return <span>&nbsp;&nbsp;Chrome</span>
    case 'installs-firefox':
      return <span>&nbsp;&nbsp;Firefox</span>
    case 'installs-edge':
      return <span>&nbsp;&nbsp;Edge</span>
    case 'mixpanel-n-recommendations':
      return (
        <span>
          # recommendations&nbsp;
          <a
            href="https://mixpanel.com/project/3014392/view/3532696/app/boards#id=7808421"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'mixpanel-n-recommendations-unique-users':
      return <span># unique users (recommendations)</span>
    case 'mixpanel-signups':
      return (
        <span>
          # signups&nbsp;
          <a
            href="https://mixpanel.com/s/rrFji"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'mixpanel-plans':
      return (
        <span>
          # new plans{' '}
          <a
            href="https://mixpanel.com/project/3014392/view/3532696/app/boards#id=6161437&edited-bookmark=FL1ghK7ZNimL"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'mixpanel-n-page-views-privacypartyapp':
      return (
        <span>
          &nbsp;&nbsp;Privacypartyapp.com&nbsp;
          <a
            href="https://mixpanel.com/s/32inx1"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'mixpanel-n-page-views-privacypartyapp-social':
      return (
        <span>
          &nbsp;&nbsp;Privacypartyapp.com (social)&nbsp;
          <a
            href="https://mixpanel.com/s/4upeeP"
            target="_blank"
            rel="noreferrer"
          >
            [more]
          </a>
        </span>
      )
    case 'mixpanel-n-page-views-privacypartyapp-email':
      return <span>&nbsp;&nbsp;Privacypartyapp.com (email)&nbsp;</span>
    default:
      throw new Error(`Unrecognized request key: ${requestKey}`)
  }
}

function useCustomRequestHook(
  requestKey: string,
  options: { startDateAsStr?: string; endDateAsStr?: string },
  stateManagementMethods: {
    setData: (key: string, value: any) => void
    setError: (key: string, value: any) => void
    setLastUpdatedOn: (key: string, value: any) => void
    setLoadingIsDone: (key: string) => void
  },
) {
  return useRequest(() => fetch(getRequestUrlForKey(requestKey, options)), {
    onSuccess: async (response) => {
      if (response.status === 200) {
        const data = await response.json()

        stateManagementMethods.setData(requestKey, data?.timeseries)
        stateManagementMethods.setLastUpdatedOn(requestKey, data?.lastUpdatedOn)
        stateManagementMethods.setLoadingIsDone(requestKey)
      } else {
        const failureMessage = `${await response.text()} - ${response.status}`

        stateManagementMethods.setError(requestKey, failureMessage)
        stateManagementMethods.setLoadingIsDone(requestKey)
      }
    },
  })
}

/**
 * Designed to display time series data in a table format, with
 * the time series data being broken down into weekly buckets
 * (with the most recent week being a partial week).
 *
 * The component then makes requests for each time series, and formats
 * it into an HTML table.
 */
export default function MetricsKeyFunnelTable() {
  // Get array of dates corresponding to date buckets
  const dateBuckets = getDateBuckets() // sorted
  const startDate = dateBuckets[0]
  const endDate = dateBuckets[dateBuckets.length - 1]

  const allDates: Date[] = []
  for (
    let i = 0;
    i <= (endDate.getTime() - startDate.getTime()) / msInDay;
    i += 1
  ) {
    allDates.push(new Date(startDate.getTime() + i * msInDay))
  }

  const startDateAsStr = getIsoDateString(startDate)
  const endDateAsStr = getIsoDateString(endDate)

  // Figure out if this is a partial week or not
  const nDaysSinceSunday = getDaysSinceSunday(endDate)
  const latestWeekIsPartial = nDaysSinceSunday > 0

  // State objects; used to inform rendering
  const [data, setData_] = useState<{
    [k: string]: { date: string; value: number }[] | null
  }>(Object.fromEntries(metricsTableKeys.map((k) => [k, null])))
  const [errors, setError_] = useState(
    Object.fromEntries(metricsTableKeys.map((k) => [k, null])),
  )
  const [lastUpdatedOn, setLastUpdatedOn_] = useState(
    Object.fromEntries(metricsTableKeys.map((k) => [k, null])),
  )
  const [loaded, setLoaded_] = useState(
    Object.fromEntries(metricsTableKeys.map((k) => [k, false])),
  )

  // If true, the week is expanded to show daily data
  const [isExpandedWeek, setIsExpandedWeek] = useState(
    dateBuckets.map(() => false),
  )
  const toggleIsExpandedWeek = useCallback((index: number) => {
    setIsExpandedWeek((prev) => {
      const newState = [...prev]
      newState[index] = !newState[index]
      return newState
    })
  }, [])

  // Clean daily series by aligning to same series of dates
  // i.e., convert { date, value }[] => value[]
  const dataByDailyBucket = Object.fromEntries(
    Object.entries(data).map(([seriesName, dateValueRecords]) => {
      if (dateValueRecords === null) {
        return [seriesName, allDates.map(() => null)]
      }

      const valuesByDate = Object.fromEntries(
        dateValueRecords.map(({ date, value }) => [
          getIsoDateString(new Date(date)),
          value,
        ]),
      )
      return [
        seriesName,
        allDates.map((date) => valuesByDate[getIsoDateString(date)] ?? null),
      ]
    }),
  )

  // Initialize the scrollable table to be scrolled all the way to the right
  const scrollableTableRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollableTableRef.current) {
      scrollableTableRef.current.scrollLeft =
        scrollableTableRef.current.scrollWidth
    }
  })

  // Make requests
  const stateManagementMethods = {
    setData: (key, value) => setData_((prev) => ({ ...prev, [key]: value })),
    setError: (key, value) => setError_((prev) => ({ ...prev, [key]: value })),
    setLastUpdatedOn: (key, value) =>
      setLastUpdatedOn_((prev) => ({ ...prev, [key]: value })),
    setLoadingIsDone: (key) => setLoaded_((prev) => ({ ...prev, [key]: true })),
  }

  useCustomRequestHook(
    'ga-blockpartyapp',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'ga-blockpartyapp-social',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'ga-webstore',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'installs-chrome',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'installs-firefox',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'installs-edge',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'mixpanel-n-recommendations',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'mixpanel-n-recommendations-unique-users',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'mixpanel-signups',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'mixpanel-plans',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'mixpanel-n-page-views-privacypartyapp',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'mixpanel-n-page-views-privacypartyapp-social',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )
  useCustomRequestHook(
    'mixpanel-n-page-views-privacypartyapp-email',
    { startDateAsStr, endDateAsStr },
    stateManagementMethods,
  )

  // Render content

  if (Object.values(loaded).some((x) => !x)) {
    return <div>Loading...</div>
  }

  // Get column headers (ignore first bucket; format as YYYY-MM-DD)
  const colHeaders: string[] = dateBuckets
    .slice(1, dateBuckets.length)
    .map(getIsoDateString)

  // Create a map from data key => row headers and data series
  // (allows us to also easily add some customized series)
  const rowHeadersByKey = Object.fromEntries(
    metricsTableKeys.map((key) => [key, getFormattedHeaderForKey(key)]),
  )
  const dataSeriesByKey = Object.fromEntries(
    metricsTableKeys.map((key) => [key, dataByDailyBucket[key]]),
  )

  // Add additional series
  function sumSeries(...serieses: number[][]) {
    return allDates.map((date, i) =>
      serieses.reduce((acc, series) => acc + series[i] || 0, 0),
    )
  }

  rowHeadersByKey['total-visits'] = <span>Total visits</span>
  dataSeriesByKey['total-visits'] = sumSeries(
    dataSeriesByKey['ga-blockpartyapp'],
    dataSeriesByKey['mixpanel-n-page-views-privacypartyapp'],
    dataSeriesByKey['ga-webstore'],
  )

  rowHeadersByKey['total-installs'] = getFormattedHeaderForKey('total-installs')
  dataSeriesByKey['total-installs'] = sumSeries(
    dataSeriesByKey['installs-chrome'],
    dataSeriesByKey['installs-firefox'],
    dataSeriesByKey['installs-edge'],
  )

  const weeklyDataSeriesByKey = Object.fromEntries(
    Object.entries(dataSeriesByKey).map(([key, series]) => {
      const seriesAsDateValueRecords = series.map((x, i) => {
        const date = allDates[i]
        return { date: getIsoDateString(date), value: x }
      })

      return [key, aggDataIntoBuckets(seriesAsDateValueRecords, dateBuckets)]
    }),
  )

  for (const emptyRowKey of [
    'space-one',
    'space-two',
    'space-three',
    'space-four',
    'space-five',
  ]) {
    rowHeadersByKey[emptyRowKey] = <span />
    dataSeriesByKey[emptyRowKey] = allDates.map(() => null)
    weeklyDataSeriesByKey[emptyRowKey] = colHeaders.map(() => null)
  }

  // Now generate row headers & data series in the desired order
  const order = [
    'total-visits',
    'ga-blockpartyapp',
    'mixpanel-n-page-views-privacypartyapp',
    'ga-webstore',
    'space-one',
    'ga-blockpartyapp-social',
    'mixpanel-n-page-views-privacypartyapp-social',
    'space-two',
    'mixpanel-n-page-views-privacypartyapp-email',
    'space-three',
    'total-installs',
    'installs-chrome',
    'installs-firefox',
    'installs-edge',
    'space-four',
    'mixpanel-n-recommendations',
    'mixpanel-n-recommendations-unique-users',
    'space-five',
    'mixpanel-signups',
    'mixpanel-plans',
  ]
  const rowHeadersOrdered = order.map((key) => rowHeadersByKey[key])
  const dataSeriesOrdered = order.map((key) => dataSeriesByKey[key])
  const weeklyDataSeriesOrdered = order.map((key) => weeklyDataSeriesByKey[key])

  const latestFullWeekIndex = latestWeekIsPartial
    ? colHeaders.length - 2
    : colHeaders.length - 1
  const latestPartialWeekIndex = latestWeekIsPartial
    ? colHeaders.length - 1
    : null

  const seriesIsSpacer = order.map((key) => key.startsWith('space-'))

  // Render the table!
  return (
    <div className="MetricsKeyFunnelTable">
      <div>
        <h1>Key Funnel</h1>
        <ul>
          <li>
            Latest full week highlighted in{' '}
            <span className="latest-full-week">yellow</span>
          </li>
          <li>
            If latest week is not over, it is marked in{' '}
            <span className="latest-partial-week">lighter yellow</span>
          </li>
          <li>
            If series not been updated as of date in header, it is{' '}
            <span className="data-incomplete">
              italicized, red, and followed by an asterisk
            </span>{' '}
            to indicate it needs updating
          </li>
        </ul>
        <div className="metrics-tables">
          <table className="fixed-row-header-table">
            <thead>
              <tr>
                <th> Week Ending </th>
              </tr>
            </thead>
            <tbody>
              {rowHeadersOrdered.map((header, i) => (
                <tr key={`row-header-${order[i]}`}>
                  <td>{header}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="data-series-table-container" ref={scrollableTableRef}>
            <table className="data-series-table">
              <thead>
                <tr>
                  {colHeaders
                    .map((text, i) => {
                      const cells: ReactNode[] = []
                      if (isExpandedWeek[i]) {
                        const days = getDaysInWeekLeadingUpToDate(text)
                        days.forEach((date) => {
                          cells.push(
                            <th className="data-series-table-header-item daily-data">
                              {getIsoDateString(date)}
                            </th>,
                          )
                        })
                      }
                      cells.push(
                        <th
                          className={clsx(
                            'data-series-table-header-item',
                            'clickable-cell',
                            i === latestFullWeekIndex && 'latest-full-week',
                            i === latestPartialWeekIndex &&
                              'latest-partial-week',
                          )}
                          key={text}
                          onClick={() => {
                            toggleIsExpandedWeek(i)
                          }}
                        >
                          {text}
                        </th>,
                      )
                      return cells
                    })
                    .flat()}
                </tr>
              </thead>
              <tbody>
                {weeklyDataSeriesOrdered.map((dataSeries, i) => (
                  <tr key={`data-row-${order[i]}`}>
                    {dataSeries
                      .map((datum, j) => {
                        const cells: ReactNode[] = []

                        // If this week has been expanded to show daily data,
                        // build those cells first
                        if (isExpandedWeek[j]) {
                          const startIx = j * 7 + 1
                          const endIx = Math.min(j * 7 + 8, allDates.length)
                          dataSeriesOrdered[i]
                            .slice(startIx, endIx)
                            .forEach((value) => {
                              cells.push(
                                <td className="daily-data">{value}</td>,
                              )
                            })
                        }

                        // Build out week column; we mostly just need to determine
                        // if it is a partial week (affects styling)
                        const currentWeek = new Date(colHeaders[j])
                        const currentSeriesKey = order[i]
                        const isDataIncomplete = getIsDataIncomplete(
                          currentSeriesKey,
                          lastUpdatedOn,
                          currentWeek,
                          seriesIsSpacer[i],
                        )

                        cells.push(
                          <td
                            className={clsx(
                              j === latestFullWeekIndex && 'latest-full-week',
                              j === latestPartialWeekIndex &&
                                'latest-partial-week',
                              isDataIncomplete && 'data-incomplete',
                            )}
                            key={`data-row-item-${order[i]}-${colHeaders[j]}`}
                          >
                            {datum}
                            {isDataIncomplete && '*'}
                          </td>,
                        )

                        return cells
                      })
                      .flat()}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        See the following links for other useful Mixpanel boards:
        <ul className="no-lower-margin">
          <li>
            <a
              href="https://mixpanel.com/s/uYWMv"
              target="_blank"
              rel="noreferrer"
            >
              Acquisition
            </a>
          </li>
          <li>
            <a
              href="https://mixpanel.com/project/3014392/view/3532696/app/boards#id=7712909"
              target="_blank"
              rel="noreferrer"
            >
              Activation
            </a>
          </li>
          <li>
            <a
              href="https://mixpanel.com/project/3014392/view/3532696/app/boards#id=7713111"
              target="_blank"
              rel="noreferrer"
            >
              Retention
            </a>
          </li>
        </ul>
      </div>
      <div className="aux-data">
        <div className="last-updated-box">
          <h2>Last Updated On</h2>
          <table>
            {Object.entries(lastUpdatedOn).map(([key, value]) => {
              if (value === null) {
                return null
              }
              return (
                <tr key={key}>
                  <td key={key}>
                    <strong>{key}</strong>
                  </td>
                  <td>{getIsoDateString(new Date(value))}</td>
                </tr>
              )
            })}
          </table>
        </div>
        <div className="errors">
          <h2>Errors</h2>
          {Object.values(errors).every((error) => error === null) ? (
            <span>(no errors)</span>
          ) : (
            <ul>
              {Object.entries(errors).map(([key, value]) => {
                if (value === null) {
                  return null
                }
                return (
                  <li key={key}>
                    <strong>{key}</strong>: {value}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}
