import { useCallback, useMemo } from 'react'

import set from 'lodash/set'
import unset from 'lodash/unset'

import * as communication from './communication'
import {
  AutomationType,
  FBRecommendationKey,
  FullScreenModalType,
  PLATFORM,
  STORAGE_KEY,
} from './enums'
import * as localStorage from './localStorage'
import { startAutomationMachineForRecommendation } from '../automations/automationStageMachine'
import type {
  Batch,
  Friend,
  FriendsLookup,
  GatherInputData,
  State,
} from '../automations/facebook/fbUnfriend'
import { useExtensionData } from '../hooks/extensionStorageListener'

async function setAllIsSelected(
  data: any,
  platformUserId: string,
  index: number,
  value: boolean,
): Promise<void> {
  const friends: Friend[] = data.RECC_PROGRESS.FACEBOOK[
    platformUserId
  ].FB_UNFRIEND.initialState.batches[index].friends.map((f: Friend) => ({
    ...f,
    isSelected: value,
  }))
  const newData = set(
    { ...data },
    [
      STORAGE_KEY.RECC_PROGRESS,
      PLATFORM.FACEBOOK,
      platformUserId,
      FBRecommendationKey.FB_UNFRIEND,
      'initialState',
      'batches',
      index,
      'friends',
    ].join('.'),
    friends,
  )
  await localStorage.set(newData)
}

async function setIsSelected(
  data: any,
  platformUserId: string,
  index: number,
  friendId: string,
  value: boolean,
): Promise<void> {
  const friendIndex = data.RECC_PROGRESS.FACEBOOK[
    platformUserId
  ].FB_UNFRIEND.initialState.batches[index].friends.findIndex(
    (f: Friend) => f.id === friendId,
  )
  const newData = set(
    { ...data },
    [
      STORAGE_KEY.RECC_PROGRESS,
      PLATFORM.FACEBOOK,
      platformUserId,
      FBRecommendationKey.FB_UNFRIEND,
      'initialState',
      'batches',
      index,
      'friends',
      friendIndex,
      'isSelected',
    ].join('.'),
    value,
  )
  await localStorage.set(newData)
}

async function setMarkAsReviewed(
  data: any,
  platformUserId: string,
  index: number,
  value: boolean,
): Promise<void> {
  const newData = set(
    { ...data },
    [
      STORAGE_KEY.RECC_PROGRESS,
      PLATFORM.FACEBOOK,
      platformUserId,
      FBRecommendationKey.FB_UNFRIEND,
      'initialState',
      'batches',
      index,
      'reviewed',
    ].join('.'),
    value,
  )
  await localStorage.set(newData)
}

export async function openModal(
  index: number,
  platformUserId: string,
  unfriendedDuringMostRecentUpdate?: Record<string, true>,
): Promise<void> {
  await communication.sendSetActiveTabForPlatform(PLATFORM.FACEBOOK)
  const activeTabStorageKey = communication.getActiveTabStorageKeyForPlatform(
    PLATFORM.FACEBOOK,
  )
  const activeTabId = await localStorage.getSingle(activeTabStorageKey)

  let toast: string | undefined
  if (
    unfriendedDuringMostRecentUpdate &&
    Object.keys(unfriendedDuringMostRecentUpdate).length > 0
  ) {
    toast =
      Object.keys(unfriendedDuringMostRecentUpdate).length === 1
        ? '1 profile was unfriended.'
        : `${Object.keys(unfriendedDuringMostRecentUpdate).length} profiles were unfriended.`
  }

  await localStorage.set({
    [STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL]: {
      [activeTabId]: {
        selectedModalType: FullScreenModalType.FB_UNFRIEND_MODAL,
        selectedModalData: {
          index,
          platform: PLATFORM.FACEBOOK,
          platformUserId,
          toast,
        },
      },
    },
  })
}

export async function removeToast(): Promise<void> {
  const data = await localStorage.get([STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL])
  const tabIds = Object.keys(
    data?.[STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL] || {},
  )
  const newData = { ...data }
  for (const tabId of tabIds) {
    unset(
      newData,
      [
        STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL,
        tabId,
        'selectedModalData',
        'toast',
      ].join('.'),
    )
  }
  await localStorage.set(newData)
}

export async function startGather(
  loadAllFriendsWasClicked?: boolean,
  openSet4?: boolean,
): Promise<void> {
  await communication.sendSetActiveTabForPlatform(PLATFORM.FACEBOOK)
  let inputData: GatherInputData | null = null
  if (loadAllFriendsWasClicked) {
    inputData = {
      ...(inputData || {}),
      loadAllFriendsWasClicked,
    }
  }
  if (openSet4) {
    inputData = {
      ...(inputData || {}),
      openSet4,
    }
  }
  await startAutomationMachineForRecommendation(
    PLATFORM.FACEBOOK,
    FBRecommendationKey.FB_UNFRIEND,
    AutomationType.GATHER,
    inputData,
  )
  if (openSet4) {
    await communication.sendCloseModalOnCurrentTab()
  }
}

export async function startUpdate(
  index: number,
  selected: FriendsLookup,
): Promise<void> {
  await communication.sendSetActiveTabForPlatform(PLATFORM.FACEBOOK)
  const inputData = { index, selected }
  await startAutomationMachineForRecommendation(
    PLATFORM.FACEBOOK,
    FBRecommendationKey.FB_UNFRIEND,
    AutomationType.UPDATE,
    inputData,
  )
  await communication.sendCloseModalOnCurrentTab()
}

export function useFbUnfriend(platformUserId: string): {
  allFriendsAreLoaded: boolean
  batches: Batch[]
  onMarkAsReviewed: (index: number) => Promise<void>
  onSelect: (index: number, friendId: string) => Promise<void>
  onSelectAll: (index: number) => Promise<void>
  onUnmarkAsReviewed: (index: number) => Promise<void>
  onUnselect: (index: number, friendId: string) => Promise<void>
  onUnselectAll: (index: number) => Promise<void>
  openModal: (index: number, toast?: string) => Promise<void>
} {
  const { data, isLoaded } = useExtensionData({
    local: [STORAGE_KEY.RECC_PROGRESS],
  })

  const batches = useMemo<Batch[]>(() => {
    if (!isLoaded) {
      return []
    }
    return (
      data?.[STORAGE_KEY.RECC_PROGRESS]?.[PLATFORM.FACEBOOK]?.[
        platformUserId
      ]?.[FBRecommendationKey.FB_UNFRIEND]?.initialState?.batches || []
    )
  }, [data, isLoaded, platformUserId])

  const allFriendsAreLoaded: boolean = useMemo(() => {
    if (!isLoaded) {
      return false
    }
    const state: State | undefined =
      data?.[STORAGE_KEY.RECC_PROGRESS]?.[PLATFORM.FACEBOOK]?.[
        platformUserId
      ]?.[FBRecommendationKey.FB_UNFRIEND]?.initialState
    return state?.labeledTotal !== undefined && state?.remaining === 0
  }, [data, isLoaded, platformUserId])

  const handleMarkAsReviewed = useCallback(
    async (index: number) => {
      setAllIsSelected(data, platformUserId, index, false)
      setMarkAsReviewed(data, platformUserId, index, true)
    },
    [data, platformUserId],
  )

  const handleSelect = useCallback(
    async (index: number, friendId: string) => {
      setIsSelected(data, platformUserId, index, friendId, true)
    },
    [data, platformUserId],
  )

  const handleSelectAll = useCallback(
    async (index: number) => {
      setAllIsSelected(data, platformUserId, index, true)
    },
    [data, platformUserId],
  )

  const handleUnmarkAsReviewed = useCallback(
    async (index: number) => {
      setMarkAsReviewed(data, platformUserId, index, false)
    },
    [data, platformUserId],
  )

  const handleUnselect = useCallback(
    async (index: number, friendId: string) => {
      setIsSelected(data, platformUserId, index, friendId, false)
    },
    [data, platformUserId],
  )

  const handleUnselectAll = useCallback(
    async (index: number) => {
      setAllIsSelected(data, platformUserId, index, false)
    },
    [data, platformUserId],
  )

  const openModal_ = useCallback(
    async (index: number) => {
      openModal(index, platformUserId)
    },
    [platformUserId],
  )

  return {
    allFriendsAreLoaded,
    batches,
    onMarkAsReviewed: handleMarkAsReviewed,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    onUnmarkAsReviewed: handleUnmarkAsReviewed,
    onUnselect: handleUnselect,
    onUnselectAll: handleUnselectAll,
    openModal: openModal_,
  }
}
