/*
 * 1. Toast is in state {shouldRender = true, shouldWaitForTransition = false}
 *       -> This renders the toast with opacity 0, position above container
 * 2. Toast is put in state {shouldRender = true, shouldWaitForTransition = true}
 *       -> This renders the toast with opacity 1, position in container
 * 3. Toast is put in state {shouldRender = false, shouldWaitForTransition = true}
 *       -> This renders the toast with opacity 0, position above container
 * 4. Toast is put in state {shouldRender = false, shouldWaitForTransition = false}
 *       -> The toast is no longer rendered
 *
 * The transitions between steps 1, 2, 3 are animated
 */

import React, { ReactNode, useEffect, useState } from 'react'

import clsx from 'clsx'

import CloseIcon from '../icons/close-icon.svg'

import './Toast.scss'

/*
 * Hook will prompt transition 1->2 (to animate in) and transition 3->4 (to stop rendering)
 */
const useTransitionTimeout = (render, delay) => {
  const [waitForTransition, setWaitForTransition] = useState(false)

  useEffect(() => {
    let timeoutId

    if (render && !waitForTransition) {
      // This prompts the toast to transition in
      timeoutId = setTimeout(() => setWaitForTransition(true), delay)
    } else if (!render && waitForTransition) {
      // The toast will stop rendering after timeout
      timeoutId = setTimeout(() => setWaitForTransition(false), delay)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [delay, render, waitForTransition])

  return waitForTransition
}

export type Props = {
  color?: 'green' | 'red' | 'yellow'
  dismissAfterMs?: number
  noAutoDismiss?: boolean
  showCloseIcon?: boolean
  text: ReactNode
}

export default function Toast({
  text,
  noAutoDismiss = false,
  dismissAfterMs = 3000,
  showCloseIcon = false,
  color = 'yellow',
}: Props) {
  const [render, setRender] = useState(true)
  const waitForTransition = useTransitionTimeout(render, 300)

  useEffect(() => {
    if (!noAutoDismiss) {
      setTimeout(() => {
        // This prompts the transition out (steps 2->3)
        setRender(false)
      }, dismissAfterMs)
    }
  }, [render, dismissAfterMs, noAutoDismiss])

  return (
    (render || waitForTransition) && (
      <div
        className={clsx('Toast', color, render && waitForTransition && 'in')}
      >
        <div className="text body2">{text}</div>
        {showCloseIcon && (
          <CloseIcon className="close-icon" onClick={() => setRender(false)} />
        )}
      </div>
    )
  )
}
