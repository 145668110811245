import React from 'react'

import clsx from 'clsx'

import styles from './FbUnfriendCheckbox.module.scss'
import Checkbox from '../icons/checkbox.svg'

export default function FbUnfriendCheckbox({
  checked,
  variant,
}: {
  checked?: boolean
  variant?: 'friend' | 'large'
}) {
  return (
    <div
      className={clsx(
        'fbUnfriendCheckbox',
        styles.fbUnfriendCheckbox,
        checked && styles.checked,
        variant === 'friend' && styles.friend,
        variant === 'large' && styles.large,
      )}
    >
      {checked && <Checkbox />}
    </div>
  )
}
