import { StravaRecommendationKey } from '../../utils/enums'
import metadata from '../metadata/strava.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import spec from '../specs/strava.yaml'

const STRAVA_RECOMMENDATION_KEYS = [
  StravaRecommendationKey.STRAVA_VISIBILITY,
  StravaRecommendationKey.STRAVA_COMMUNITY_FEATURES,
  StravaRecommendationKey.STRAVA_FOLLOWERS,
  StravaRecommendationKey.STRAVA_MAP_VISIBILITY,
  StravaRecommendationKey.STRAVA_MENTIONS,
  StravaRecommendationKey.STRAVA_NOTIFICATIONS,
  StravaRecommendationKey.STRAVA_PASSWORD,
  StravaRecommendationKey.STRAVA_PAST_ACTIVITIES,
]

const STRAVA_RECOMMENDATIONS_REGISTRY: Record<
  StravaRecommendationKey,
  Recommendation
> = {
  [StravaRecommendationKey.STRAVA_VISIBILITY]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_VISIBILITY]),
    key: StravaRecommendationKey.STRAVA_VISIBILITY,
  },
  [StravaRecommendationKey.STRAVA_COMMUNITY_FEATURES]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_COMMUNITY_FEATURES]),
    key: StravaRecommendationKey.STRAVA_COMMUNITY_FEATURES,
  },
  [StravaRecommendationKey.STRAVA_MENTIONS]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_MENTIONS]),
    key: StravaRecommendationKey.STRAVA_MENTIONS,
  },
  [StravaRecommendationKey.STRAVA_MAP_VISIBILITY]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_MAP_VISIBILITY]),
    key: StravaRecommendationKey.STRAVA_MAP_VISIBILITY,
  },
  [StravaRecommendationKey.STRAVA_PAST_ACTIVITIES]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_PAST_ACTIVITIES]),
    key: StravaRecommendationKey.STRAVA_PAST_ACTIVITIES,
  },
  [StravaRecommendationKey.STRAVA_NOTIFICATIONS]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_NOTIFICATIONS]),
    key: StravaRecommendationKey.STRAVA_NOTIFICATIONS,
  },
  [StravaRecommendationKey.STRAVA_FOLLOWERS]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_FOLLOWERS]),
    key: StravaRecommendationKey.STRAVA_FOLLOWERS,
  },
  [StravaRecommendationKey.STRAVA_PASSWORD]: {
    ...parseSpec(spec[StravaRecommendationKey.STRAVA_PASSWORD]),
    key: StravaRecommendationKey.STRAVA_PASSWORD,
  },
}

export const stravaRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => STRAVA_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => STRAVA_RECOMMENDATION_KEYS,
  getRecommendationsRegistry: () => STRAVA_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
