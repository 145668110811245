import React, { MouseEventHandler, ReactNode } from 'react'

import ActionButton, {
  ActionButtonIcon,
  ActionButtonSize,
  ActionButtonType,
} from './ActionButton'

export function UpdateForMeButton({ onClick, disabled = false }) {
  return (
    <ActionButton
      disabled={disabled}
      onClick={onClick}
      type={ActionButtonType.PRIMARY}
    >
      Update for me
    </ActionButton>
  )
}

export function MarkAsDoneButton({ onClick, disabled = false }) {
  return (
    <ActionButton
      iconName={ActionButtonIcon.CHECKMARK}
      onClick={onClick}
      type={ActionButtonType.PRIMARY}
      disabled={disabled}
    >
      Mark as done
    </ActionButton>
  )
}

export function CheckSettingsButton({
  onClick,
  includeIcon = true,
  disabled = false,
}) {
  return (
    <ActionButton
      iconName={includeIcon ? ActionButtonIcon.SETTINGS_CHECK : undefined}
      onClick={onClick}
      type={ActionButtonType.PRIMARY}
      disabled={disabled}
    >
      Scan settings
    </ActionButton>
  )
}

export function CheckPhotosButton({ onClick, disabled = false }) {
  return (
    <ActionButton
      iconName={ActionButtonIcon.PHOTOS_CHECK}
      onClick={onClick}
      type={ActionButtonType.PRIMARY}
      disabled={disabled}
    >
      Scan photos
    </ActionButton>
  )
}

export function UndoButton({
  onClick,
  text,
  disabled = false,
  includeIcon = true,
}) {
  return (
    <ActionButton
      disabled={disabled}
      iconName={includeIcon ? ActionButtonIcon.UNDO : undefined}
      onClick={onClick}
      size={ActionButtonSize.EXTRA_SMALL}
      type={ActionButtonType.TERTIARY}
    >
      {text}
    </ActionButton>
  )
}

export function SkipButton({ onClick, disabled = false }) {
  return (
    <ActionButton
      type={ActionButtonType.TERTIARY}
      onClick={onClick}
      disabled={disabled}
    >
      Skip
    </ActionButton>
  )
}

export function WarningButton({
  buttonText,
  disabled = false,
  onClick,
}: {
  buttonText?: ReactNode
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <ActionButton
      type={ActionButtonType.WARNING}
      onClick={onClick as () => {}}
      disabled={disabled}
    >
      {buttonText || 'Continue update'}
    </ActionButton>
  )
}

export function WarningCancelButton({ onClick, disabled = false }) {
  return (
    <ActionButton
      type={ActionButtonType.TERTIARY}
      onClick={onClick}
      disabled={disabled}
    >
      Cancel
    </ActionButton>
  )
}

export function ReviewButton({ onClick, disabled = false }) {
  return (
    <ActionButton
      type={ActionButtonType.SECONDARY}
      size={ActionButtonSize.EXTRA_SMALL}
      onClick={onClick}
      disabled={disabled}
    >
      Review
    </ActionButton>
  )
}
export function LoadMoreButton({ onClick, buttonText, disabled = false }) {
  return (
    <ActionButton
      type={ActionButtonType.SECONDARY_ROW}
      size={ActionButtonSize.SMALL}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </ActionButton>
  )
}

export function RerunButton({
  onClick,
  buttonText,
  photoRerun = false,
  disabled = false,
}) {
  const icon = photoRerun
    ? ActionButtonIcon.PHOTOS_CHECK
    : ActionButtonIcon.SETTINGS_CHECK

  return (
    <ActionButton
      iconBorder
      iconName={icon}
      type={ActionButtonType.SECONDARY}
      size={ActionButtonSize.SMALL}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </ActionButton>
  )
}

export function TaskButton({
  buttonText,
  buttonHelperText,
  disabled,
  onClick,
}: {
  buttonText: ReactNode
  buttonHelperText?: string
  disabled: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <ActionButton
      disabled={disabled}
      type={ActionButtonType.DANGER}
      size={ActionButtonSize.SMALL}
      onClick={onClick as () => {}}
    >
      {buttonText}
      {buttonHelperText && (
        <span className="body3 no-text-transform">{buttonHelperText}</span>
      )}
    </ActionButton>
  )
}
