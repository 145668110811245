import React, { useCallback, useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'
import range from 'lodash/range'

import InteractiveElement from './InteractiveElement'
import ProtipBanner from './ProtipBanner'
import styles from './TaskContentSelectDeleteTimeframe.module.scss'
import { updateRecommendation } from '../state'
import {
  getElapsedMonthsByYear,
  getOlderThanMonthYear,
  isMoreThanNumMonthsAgo,
  MONTHS,
  OLDER_THAN_OPTION,
} from '../utils/dateUtils'
import { PLATFORM, TwitterRecommendationKey } from '../utils/enums'

function getStatusMessage(
  selectedDateOption: OLDER_THAN_OPTION,
  year: number,
  month: number,
  numPosts: any,
  mostRecentPostDate: any,
  count: any,
) {
  if (numPosts) {
    if (parseInt(numPosts, 10) > 1000) {
      if (selectedDateOption === OLDER_THAN_OPTION.NOW) {
        return 'X may limit our ability to reach posts that far back'
      } else if (mostRecentPostDate) {
        const isMoreThanAYearAgo = isMoreThanNumMonthsAgo(
          new Date(year, month).getTime(),
          12,
          Date.parse(mostRecentPostDate),
        )
        if (isMoreThanAYearAgo) {
          return 'X may limit our ability to reach posts that far back'
        }
      } else {
        return 'X may limit our ability to reach older posts'
      }
    } else if (mostRecentPostDate) {
      // Not more than 1000 posts, use more than 5 years ago rather than 1
      const isMoreThanFiveYearsAgo = isMoreThanNumMonthsAgo(
        new Date(year, month).getTime(),
        60,
        Date.parse(mostRecentPostDate),
      )

      if (isMoreThanFiveYearsAgo) {
        return 'X may limit our ability to reach posts that far back'
      }
      return `Deleted ${count || 0} total posts.`
    } else {
      return `Deleted ${count || 0} total posts.`
    }
  }
  return null
}

export default function TaskContentSelectDeleteTimeframe({
  locked,
  platformUserId,
  updateTaskButtonText,
  updateFooterText,
  initialNumPosts,
  initialMostRecentPostDate,
  initialCountDeleted,
  selectedDateOption = OLDER_THAN_OPTION.NOW,
  selectedMonth = new Date().getMonth().toString(),
  selectedYear = new Date().getFullYear().toString(),
}: {
  locked: boolean
  platformUserId: string
  updateTaskButtonText: (text: string) => void
  updateFooterText: (text: string) => void
  initialNumPosts: string
  initialMostRecentPostDate: string
  initialCountDeleted: string
  selectedDateOption: OLDER_THAN_OPTION
  selectedMonth: string
  selectedYear: string
}) {
  const [showDryTestBanner, setShowDryTestBanner] = useState(false)
  const resetBanner = () => setShowDryTestBanner(false)

  const [hasVotedForDryTest, setHasVotedForDryTest] = useState(false)

  const voteForDryTest = useCallback(() => {
    setShowDryTestBanner(true)
    setHasVotedForDryTest(true)
  }, [])

  const [month, year] = useMemo(
    () =>
      getOlderThanMonthYear(selectedDateOption, selectedMonth, selectedYear),
    [selectedDateOption, selectedMonth, selectedYear],
  )

  useEffect(() => {
    updateTaskButtonText(`before ${MONTHS[month]} 1, ${year}`)
  }, [updateTaskButtonText, month, year])

  const saveSelectedDates = useCallback(
    (d, m, y) => {
      updateRecommendation(
        PLATFORM.TWITTER,
        platformUserId,
        TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE,
        {
          settingsSelected: {
            dateOption: d,
            sinceDateMonth: m,
            sinceDateYear: y,
          },
        },
        { doNotUpdateLastUpdatedTimestamp: true },
      )
    },
    [platformUserId],
  )

  useEffect(() => {
    if (initialNumPosts) {
      const statusMessage = getStatusMessage(
        selectedDateOption,
        year,
        month,
        initialNumPosts,
        initialMostRecentPostDate,
        initialCountDeleted,
      )
      updateFooterText(statusMessage)
    }
  }, [
    selectedDateOption,
    year,
    month,
    initialNumPosts,
    initialMostRecentPostDate,
    initialCountDeleted,
    updateFooterText,
  ])

  const months = useMemo(
    () => getElapsedMonthsByYear(selectedYear),
    [selectedYear],
  )

  const handleSelectOption = useCallback(
    (event) => {
      resetBanner()
      const val = event.target.value
      saveSelectedDates(val, selectedMonth, selectedYear)
    },
    [saveSelectedDates, selectedMonth, selectedYear],
  )

  const handleSetMonth = useCallback(
    (event) => {
      const targetMonth = event.target.value
      saveSelectedDates(selectedDateOption, targetMonth, selectedYear)
    },
    [selectedDateOption, selectedYear, saveSelectedDates],
  )

  const handleSetYear = useCallback(
    (event) => {
      const targetYear = event.target.value
      saveSelectedDates(selectedDateOption, selectedMonth, targetYear)
    },
    [selectedDateOption, selectedMonth, saveSelectedDates],
  )

  /* eslint-disable no-nested-ternary */
  return (
    <div className={clsx(styles.TaskContentSelectDeleteTimeframe)}>
      <div className="body1 black bold">
        How old are the posts you want to delete?
      </div>
      <div className={clsx(styles.cardBody)}>
        <div className={clsx(styles.selectDate, 'body2')}>
          <select
            className={clsx(styles.dateDropdown)}
            disabled={locked}
            id="dateSelector"
            name="date"
            onChange={handleSelectOption}
            value={selectedDateOption}
          >
            <option value={OLDER_THAN_OPTION.NOW}>All posts</option>
            <option value={OLDER_THAN_OPTION.ONE_MONTH}>
              Older than 1 month
            </option>
            <option value={OLDER_THAN_OPTION.TWO_MONTHS}>
              Older than 2 months
            </option>
            <option value={OLDER_THAN_OPTION.THREE_MONTHS}>
              Older than 3 months
            </option>
            <option value={OLDER_THAN_OPTION.CHOOSE_MONTH_YEAR}>
              Choose a month and year...
            </option>
          </select>
        </div>
        {selectedDateOption === OLDER_THAN_OPTION.CHOOSE_MONTH_YEAR &&
          !locked && (
            <div className={clsx(styles.monthYearSelector, 'body2')}>
              <div>Before</div>
              <select
                id="monthSelector"
                name="month"
                onChange={handleSetMonth}
                value={selectedMonth}
              >
                {months.map((m) => (
                  <option value={m} key={m}>
                    {MONTHS[m]}
                  </option>
                ))}
              </select>
              <select
                id="yearSelector"
                name="year"
                onChange={handleSetYear}
                value={selectedYear}
              >
                {range(new Date().getFullYear(), 2006).map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
          )}
        {selectedDateOption !== OLDER_THAN_OPTION.NOW &&
          selectedDateOption !== OLDER_THAN_OPTION.CHOOSE_MONTH_YEAR &&
          !locked && (
            <>
              <div className="body2">
                <div className={clsx(styles.rollingDeleteOption)}>
                  <input type="radio" checked readOnly /> Just this once
                </div>
                <div className={clsx(styles.rollingDeleteOption, 'dark-gray')}>
                  <input type="radio" disabled />
                  <div>
                    Automatically on a rolling basis{' '}
                    {!hasVotedForDryTest && (
                      <InteractiveElement
                        aria-label={`I want ${selectedDateOption} month rolling deletes`}
                        onClick={voteForDryTest}
                      >
                        — <span className="link2 purple">I want this</span>
                      </InteractiveElement>
                    )}
                  </div>
                </div>
              </div>
              {showDryTestBanner && (
                <ProtipBanner hideIcon variant="blue">
                  Thanks for letting us know! We'll alert you when this feature
                  is available. In the meantime, you can come back and delete
                  your old posts as regularly as you need to
                </ProtipBanner>
              )}
            </>
          )}
      </div>
    </div>
  ) // past year selection cuts at twitter being founded in 2006
}
