import React from 'react'

import { InstagramAccountVisibility } from '../automations/instagram/accountEnums'
import PublicIcon from '../icons/public-icon.svg'

import './RecommendationCardSubheaders.scss'

export function InstagramAccountTypeSubheader({ type }) {
  switch (type) {
    case InstagramAccountVisibility.PUBLIC:
      return (
        <div className="InstagramPublicSubheader subheader">
          <div className="body2 text">Best for public accounts</div>
          <PublicIcon className="icon public-icon" />
        </div>
      )
    default:
  }
  return null
}
