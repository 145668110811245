import React, { MouseEventHandler, useCallback, useMemo } from 'react'

import FriendSets from './FriendSets'
import UnfriendingCount from './UnfriendingCount'
import { State } from '../../automations/facebook/fbUnfriend'
import FbUnfriend from '../../images/fb-unfriend.svg'
import { RecommendationCardDisplayData } from '../../recommendations/recommendationProgressTypes'
import { startGather, useFbUnfriend } from '../../utils/fbUnfriend'
import ActionButton, { ActionButtonType } from '../ActionButton'
import ProtipBanner from '../ProtipBanner'
import styles from '../TaskRecommendationCardBody.module.scss'

export default function FbUnfriendRecommendationCardBody({
  locked,
  platformUserId,
  progress,
}: {
  locked: boolean
  platformUserId: string
  progress: RecommendationCardDisplayData
}) {
  const { allFriendsAreLoaded, openModal } = useFbUnfriend(platformUserId)

  const state: State | null = progress.initialState as State | null

  const isPreGather = useMemo(
    () => !state || state?.batches.length === 0,
    [state],
  )

  const handleStartReviewingButtonClick = useCallback(() => {
    startGather()
  }, [])

  const handleLoadAllFriendsClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      startGather(true)
    }, [])

  const handleBatchSelected = useCallback(
    (index: number) => openModal(index),
    [openModal],
  )

  return (
    <div className={styles.fbUnfriendRecommendationCardBody}>
      <div className={styles.recommendationBody}>
        <ProtipBanner variant="blue" iconType="people">
          When you have many Facebook friends, reviewing in{' '}
          <strong>sets of 100</strong> helps make cleanup manageable
        </ProtipBanner>
        {isPreGather && (
          <>
            <div className={styles['recommendation-body-title']}>
              <div className="body1 black bold">
                Multi-select makes cleanup easy:
              </div>
            </div>
            <FbUnfriend />
          </>
        )}
        {!isPreGather && (
          <div className={styles['recommendation-body-title']}>
            <div className="body1 black bold">
              Review and clean up your friends list:
            </div>
            <FriendSets
              allFriendsAreLoaded={allFriendsAreLoaded}
              onBatchSelected={handleBatchSelected}
              onLoadAllFriendsClick={handleLoadAllFriendsClick}
              state={state}
            />
          </div>
        )}
      </div>
      <div className={styles.recommendationFooter}>
        {isPreGather && (
          <div className={styles['footer-buttons']}>
            <ActionButton
              disabled={locked}
              onClick={handleStartReviewingButtonClick}
              type={ActionButtonType.PRIMARY}
            >
              Start reviewing
            </ActionButton>
          </div>
        )}
        {!isPreGather && (
          <UnfriendingCount unfriended={state?.unfriended || {}} />
        )}
      </div>
    </div>
  )
}
