import React, { useCallback, useMemo } from 'react'

import styles from './FriendSet.module.scss'
import ActionButton, {
  ActionButtonSize,
  ActionButtonType,
} from '../../ActionButton'
import FbUnfriendCheckbox from '../../FbUnfriendCheckbox'

export default function FriendSet({
  index,
  onBatchSelected,
  reviewed,
}: {
  index: number
  onBatchSelected: (index: number) => void
  reviewed: boolean
}) {
  const title = useMemo(() => `Set ${index + 1}`, [index])

  const handleClick = useCallback(async () => {
    onBatchSelected(index)
  }, [index, onBatchSelected])

  return (
    <div className={styles.friendSet}>
      <ActionButton
        onClick={handleClick}
        size={ActionButtonSize.EXTRA_SMALL}
        type={ActionButtonType.SECONDARY}
      >
        {title}
      </ActionButton>
      <FbUnfriendCheckbox checked={reviewed} />
    </div>
  )
}
